import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./ins_new.scss";
import { TextField } from "@material-ui/core";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";

import BoxOption from "../SliderSecurity/BoxOption";
import more from "./../../assets/more.png";
import less from "./../../assets/less.png";
import { Button2, ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";

function InsForm3(props) {
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedValue, setSelectedValue] = useState("a");

  const [prima, setPrima] = useState("");
  const [option, setOption] = useState("");
  const [monto, setMonto] = useState("");

  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  async function sendData() {
    let data = {};
    console.log(data);
    await api.updateForm(data, filledFormId);
    var currentForm = await api.getFilledForm(filledFormId);
    console.log(currentForm);
  }

  let values = {
    a: {
      option: "1",
      prima: "37,06",
      monto: "63.525",
    },
    b: {
      option: "2",
      prima: "75,00",
      monto: "132.275",
    },
    c: {
      option: "3",
      prima: "116,58",
      monto: "132.275",
    },
    d: {
      option: "4",
      prima: "123,52",
      monto: "132.275",
    },
  };

  useEffect(() => {
    console.log(selectedValue);
    setOption(values[selectedValue].option);
    setPrima(values[selectedValue].prima);
    setMonto(values[selectedValue].monto);
  }, [selectedValue]);


  function nextPage() {
    if (currentPage === 0) setCurrentPage(1);
  }

  function prevPage() {
    if (currentPage === 1) setCurrentPage(0);
  }

  async function sendData() {
    console.log("next screen");
    var data = {
      opcion: option,
      prima: prima,
      monto: monto,
    };
    console.log(data);
    await api.updateForm(data, filledFormId);
    var currentForm = await api.getFilledForm(filledFormId);
    console.log(currentForm);
    history.push("/insResumen1" + history.location.search);
  }

  if (currentPage === 0)
    return (
      <>
        <div
          style={{
            background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
            position: "fixed",
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="col">
            <p className="txt-title">Selecciona la opción de aseguramiento</p>
            <BoxOption
              option="OPCIÓN 01"
              price1="$ 37.06"
              text="Muerte Accidental del Asegurado Gastos Médicos y Adicionales Renta Diaria por Hospitalización Cancelación y acortamiento de viaje Retraso en el Viaje Pérdida de Equipaje Pérdida de Pasaporte Responsibilidad Civil x Lesión y/o muerte Honorarios Legales x Acc. Transporte Fianzas Legales"
              price2="$ 63.525"
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              value="a"
            />
            <BoxOption
              option="OPCIÓN 02"
              price1="$ 75.00"
              text="Muerte Accidental del Asegurado Gastos Médicos y Adicionales Renta Diaria por Hospitalización Cancelación y acortamiento de viaje Retraso en el Viaje Pérdida de Equipaje Pérdida de Pasaporte Responsibilidad Civil x Lesión y/o muerte Honorarios Legales x Acc. Transporte Fianzas Legales"
              price2="$ 132.275"
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              value="b"
            />

            <div className="row btn-more" onClick={() => nextPage()}>
              <div className="col">
                <p className="row-12" style={{ width: "100%" }}>
                  ver más<br></br>
                  opciones
                </p>
                <img src={more} alt="more" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div className="col">
          <p className="txt-title">Selecciona la opción de aseguramiento</p>
          <BoxOption
            option="OPCIÓN 03"
            price1="$ 116,58"
            text="Muerte Accidental del AseguradoGastos Médicos y AdicionalesRenta Diaria por HospitalizaciónCancelación y acortamiento de viajeRetraso en el ViajePérdida de EquipajePérdida de PasaporteResponsibilidad Civil x Lesión y/o muerteHonorarios Legales x Acc. TransporteFianzas Legales"
            price2="$ 132.275"
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            value="c"
          />
          <BoxOption
            option="OPCIÓN 04"
            price1="$ 123,52"
            text="Muerte Accidental del AseguradoGastos Médicos y AdicionalesRenta Diaria por HospitalizaciónCancelación y acortamiento de viajeRetraso en el ViajePérdida de EquipajePérdida de PasaporteResponsibilidad Civil x Lesión y/o muerteHonorarios Legales x Acc. TransporteFianzas Legales"
            price2="$ 132.275"
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            value="d"
          />
          <div className="row btn-less" onClick={() => prevPage()}>
            <div className="col">
              <img src={less} alt="less" />
              <p className="row-12" style={{ width: "100%" }}>
                ver más<br></br>
                opciones
              </p>
              <div style={{ margin: "2.4em", paddingBottom: "3em" }}>
                <ButtonStyle
                  text="SIGUIENTE"
                  onClick={() => sendData()}
                  width="177px"
                  height="5vh"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default InsForm3;
