import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import { getColorFromCode, updateData } from "../api/magic_forms_2_api";
import useQuery from "../../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import FormField from "../dtos/FormField";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import { infoMessage } from "../../api/errorHandler";
import { ConstructionOutlined } from "@mui/icons-material";
import { TextFieldStyleDummies } from "../../utils/TextFieldStyle/TextFieldStyle";
import { create, getColorsByFlowId, getCoreIdLink } from "../api/FlowApi";

const NewDummiesIntro = () => {
  //query params
  const query = useQuery();
  const flowId = query.get("flowId") ?? "error";
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [email, setEmail] = useState("");

  //get colors
  async function getColors() {
    let resp = await getColorsByFlowId(flowId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
  }

  //Create
  async function createProcess() {
    let resp = await create(flowId, email);
    // await infoMessage(resp.id);
    getCoreIdRedir(resp.id);
  }

  //Get CoreId Link
  async function getCoreIdRedir(processId: string) {
    let resp = await getCoreIdLink(processId);
    // await infoMessage(resp.url);
    window.location.href = resp.url;
  }

  useEffect(() => {
    getColors();
  }, []);

  //Render
  return (
    <div
      style={{
        background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          left: "50%",
        }}
      >
        <h1 className="txt-label-title" style={{ fontSize: "" }}>
          ¡Hola!
        </h1>
        <div style={{ marginBottom: "20px" }} />
        <h1 className="txt-label" style={{ fontSize: "" }}>
          Iniciemos creando tu identidad digital
        </h1>
        <div style={{ marginBottom: "20px" }} />
        <div
          style={{
            marginTop: "40px",
          }}
        ></div>
        <ButtonStyle
          onClick={() => {
            createProcess();
          }}
          text="Comenzar"
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
};

export default NewDummiesIntro;
