import "./App.css";
import AppRouter from "./AppRouter";
import { BrowserRouter as Router } from "react-router-dom";
import { enableMapSet } from "immer";
import { CookiesProvider } from "react-cookie";

function App() {
  enableMapSet();
  return (
    <CookiesProvider>
      <Router>
        <div className="App">
          <AppRouter />
        </div>
      </Router>
    </CookiesProvider>
  );
}

export default App;
