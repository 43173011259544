import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3 } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";

function InitialForm(props) {
  const query = useQuery();
  const id = query.get("id");
  console.log(id);
  const api = new DummiesApi();
  var [data, setData] = useState();
  var [txtFields, setTxtFields] = useState({});
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  // var [bgColor, setBgColor] = useState(["#292840", "#566075"]);
  useEffect(() => {
    console.log("loading data... for id: " + id);
    api.getForm(id).then((res) => {
      setData(res);
      let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
      console.log(newColor);
      setBgColor(newColor);
    });
  }, []);

  async function generateData() {
    var accept = true;
    console.log(txtFields);
    for (const [key, value] of Object.entries(txtFields)) {
      if (!value || value === "") {
        accept = false;
      }
    }
    if (!accept) {
      infoMessage("Por favor llena todos los datos");
      return;
    }
    let formRes = await api.sendForm(txtFields, id);
    if (!formRes) {
      infoMessage("Error enviando formulario!");
      return;
    }
    console.log(formRes);
    let coreIdLinkRes = await api.getCoreIdLink(formRes.id);
    console.log(coreIdLinkRes.url);
    successMessage("Ahora vamos a validar tu identidad").then((_) => {
      window.location.href = coreIdLinkRes.url;
    });
  }

  if (!data) {
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <h1 style={{ color: "white" }}>Cargando...</h1>
      </div>
    );
  }
  let rows = [];
  data.fields.map((field, i) => {
    let fieldId = field.id ? field.id : i;
    if (!field.autoLoadable) {
      txtFields[fieldId] = "";
      function oc(ev) {
        let txt = ev.target.value;

        txtFields[fieldId] = txt;
      }
      var f = (
        <GenericField
          onChange={(txt) => oc(txt)}
          title={field.title}
          key={i}
          fieldType={field.type}
        />
      );
      rows.push(f);
    }
  });
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="col"
        style={{ paddingTop: "10%", marginLeft: "10vw", marginRight: "10vw" }}
      >
        <h1 className="txt-label">Ingresa la siguiente información</h1>
        {rows}
      </div>
      <div style={{ marginTop: "5%", marginBottom: "30%" }}>
        <Button
          className="button-custom"
          text="Siguiente"
          onClick={() => generateData()}
        />
      </div>
    </div>
  );
}

export default InitialForm;
