import React from "react";
import Branding from "./../Branding/Branding";
import ColorsSystem from "./../ColorsSystem/ColorsSystem";
import Typography from "./../Typography/Typography";
import QuestionTexts from "./../QuestionText/QuestionText";
import QuestionLongText from "./../QuestionLongText/QuestionLongText";
import InputT from "../InputT/InputT";
import NumberQuestion from "../NumberQuestion/NumberQuestion";
import ButtonsComp from "../ButtonsComp/ButtonsComp";
import Degraded from "../Degraded/Degraded";
import PaletteColorFul from "../PaletteColorFul/PaletteColorful";
import QuestionCalendar from "../QuestionCalendar/QuestionCalendar";
import ProgressBar from "../ProgressBar/ProgressBar"
import MultipleChoice from "../MultipleChoice/MultipleChoice"
import LogoLocation from './../LogoLocation/LogoLocation'
function ScreenComponents() {
  return (
    <div>
      <Branding />
      <ColorsSystem />
      <Typography />
      <InputT />
      <QuestionTexts />
       <QuestionCalendar/> 
       <MultipleChoice /> 
       <LogoLocation/> 
      <NumberQuestion />
      <ButtonsComp />
      <ProgressBar />
       <PaletteColorFul/> 
      <Degraded />
    </div>
  );
}
export default ScreenComponents;
