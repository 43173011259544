import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3 } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";
import { useHistory } from "react-router-dom";

function WelcomeFormScreen2(props) {
  let history = useHistory();
  const query = useQuery();
  const id = query.get("id");
  const api = new DummiesApi();
  var [data, setData] = useState();
  // var [bgColor, setBgColor] = useState(["#292840", "#566075"]);
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  var [welcomeMessage, setWelcomeMessage] = useState("");
  var [buttonMsg, setButtonMsg] = useState("Continuar");

  //Load Data
  useEffect(() => {
    console.log("loading data... for id: " + id);
    if (!id) {
      infoMessage("Su código no es válido!");
      return;
    }
    api.getForm(id).then((res) => {
      if (res) {
        setData(res);
        let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
        setBgColor(newColor);
        console.log(res)
        if (res.welcomeScreenConfig) {
          if (res.welcomeScreenConfig.message) {
            setWelcomeMessage(res.welcomeScreenConfig.message);
          }
          if (res.welcomeScreenConfig.buttonMessage) {
            setButtonMsg(res.welcomeScreenConfig.buttonMessage);
          }
        }
      }
    });
  }, []);

  async function gotoCoreId() {
    //Create the form and get id
    let formRes = await api.sendForm({}, id);
    if (!formRes) {
      infoMessage("Error enviando formulario!");
      return;
    }
    //Get CoreId Link
    console.log(formRes);
    let coreIdLinkRes = await api.getCoreIdLinkAlt(formRes.id);
    console.log(coreIdLinkRes.url);
    successMessage("Ahora vamos a validar tu identidad").then((_) => {
      window.location.href = coreIdLinkRes.url;
    });
  }

  if (!data) {
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <h1 style={{ color: "white" }}>Cargando...</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div>
        <div className="col" style={{ marginTop: "20vh" }}>
          <h1 className="txt-label">¡Hola!</h1>
          <h1 className="txt-label txt-welcomeMessage">
            {/* En este momento navegas por una plataforma segura */}
            {welcomeMessage}
          </h1>
          <div style={{ marginTop: "20%", marginBottom: "30%" }}>
            <Button
              className="button-custom"
              text={buttonMsg}
              onClick={() => gotoCoreId()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeFormScreen2;
