import React, { useState } from "react";
import "./NumberQuestion.scss";
import OtpInput from "react-otp-input-rc-17";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
function NumberQuestion() {
  const [opt, setOpt] = useState("");
  const [errorOtp, setErrorOtp] = useState(true);
  const handleChange = (opt2) => setOpt(opt2);
  return (
    <div className="code-number-question">
      <div className="mini-nav">
        <p>Code number question</p>
      </div>
      <Box sx={{ flexGrow: 2 }} className="container-box">
        {/* ----------------INPUT1---------------------- */}
        <Grid container spacing={1} style={{ justifyContent: "space-around" }}>
          <Grid>
            <div>
              <p>Inactive</p>
              <div className="otp-inputs-code">
                <OtpInput
                  isDisabled={true}
                  inputStyle="inputs-code"
                  value={opt}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                />
              </div>
            </div>

            <div>
              {" "}
              <p>Focus</p>
              <div className="otp-inputs-code">
                <OtpInput
                  focusStyle={true}
                  inputStyle="inputs-code"
                  value={opt}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                />
              </div>
            </div>
          </Grid>
          {/* ----------------INPUT2---------------------- */}

          <Grid>
            <div>
              <p>Active</p>
              <div className="otp-inputs-code">
                <OtpInput
                  inputStyle="inputs-code"
                  value={opt}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                />
              </div>
            </div>
            <div>
              <p>Error</p>
              <div className="otp-inputs-code">
                <OtpInput
                  hasErrored={true}
                  inputStyle="inputs-code"
                  value={opt}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                />
                {errorOtp ? (
                  <p style={{ color: "#EA676A" }}>Error Message</p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default NumberQuestion;
