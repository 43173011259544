import React from "react";
import "./ImprontaAdvice.scss";
import icon from "./../../assets/email.png";
import { Button1, Button } from "./../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'
function Impronta(props) {
    const history= useHistory()
    const SendInstructions =()=>{
        history.push('./toma1'+ history.location.search)
    }
  return (
    <div className="impronta-advice">
      <p className='text-1'>
        Toma de <br></br>Improntas
      </p>
      <p className='text-2'>
        RECIBE EN TU <br></br>CORREO
      </p>
      <img src={icon} alt="icon" />
      <p className='text-3'>
        INSTRUCCIONES DE <br></br> COMO TOMAR LAS <br></br> IMPRONTAS <br></br>
        DE MI VEHÍCULO
      </p>
      <p className='text-4'>
        Si por algún motivo no esta el correo en <br></br> tu bandeja de
        entrada revisa la carpeta <br></br> de spam
      </p>
      <div>
        <Button1 text="RE-ENVIAR CORREO" width="177px" height="6vh" onClick={()=>props.SendInstructions()}/>
      </div>
      <div style={{marginTop: "2em" }}>
        <Button  text="ENVIAR INSTRUCCIONES" onClick={()=>props.SendInstructions()} width="177px" height="19%"></Button>
      </div>
    </div>
  );
}
export default Impronta;
