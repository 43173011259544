import { useHistory } from "react-router-dom";
import useQuery from "../../utils/UseQuery";
import { PopUpElement } from "../agentComponents/PopUpElement";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  resetErrorMessage,
  selectErrorMessage,
  selectLoadingPauseVerify,
  selectPauseRedirect,
  selectToken,
  tryPause,
  verifyPause,
} from "./formComponentSlicer";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { ReactComponent as Info } from "../../assets/info.svg";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { getRedirectionUrl } from "../api/magic_forms_2_api";

export const ConfirmPause = () => {
  const query = useQuery();
  const history = useHistory();
  const code = query.get("code") ?? "error";
  const pauseCode = query.get("pauseCode") ?? "";

  const [stepPause, setStepPause] = useState(0);

  let dispatch = useAppDispatch();
  let errorMessage = useAppSelector(selectErrorMessage);
  let loading = useAppSelector(selectLoadingPauseVerify);
  let pauseRedirect = useAppSelector(selectPauseRedirect);
  let token = useAppSelector(selectToken);

  const errorPopup = () => {
    if (errorMessage) {
      switch (errorMessage) {
        case "Invalid Token":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El formulario no es válido, revisa el enlace o intenta de
                    nuevo
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetErrorMessage());
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              }
            />
          );
        case "Invalid Credentials":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El formulario no es válido, revisa el enlace o intenta de
                    nuevo
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetErrorMessage());
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              }
            />
          );
        case "Invalid Code":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El código no es válido
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetErrorMessage());
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              }
            />
          );
        case "Form Done":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El formulario ya ha sido completado
                  </p>
                  <button
                    onClick={async (e) => {
                      e.preventDefault();
                      let resp = await getRedirectionUrl(token);
                      let redirectionUrl = "https://anteia.co";
                      if (resp.success) {
                        redirectionUrl = resp.url!;
                      }
                      window.location.href =
                        redirectionUrl || "https://anteia.co";
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Continuar
                  </button>
                </div>
              }
            />
          );
        case "Expired":
          return (
            <PopUpElement
              content={
                stepPause === 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <h5
                      style={{
                        textAlign: "start",
                        fontSize: "24px",
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        color: "#000000",
                        margin: "0",
                      }}
                    >
                      Información
                    </h5>
                    <Info
                      style={{
                        width: "70px",
                        height: "70px",
                        margin: "10px 0 10px 0",
                      }}
                    />
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "initial",
                        fontSize: "18px",
                        color: "#000000",
                        textAlign: "start",
                      }}
                    >
                      El código ha expirado, sin embargo, si deseas continuar
                      con el formulario, dale clic a aceptar, y te enviaremos un
                      correo electrónico, con el cual puedes continuar el
                      formulario.
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(tryPause(code));
                        setStepPause(1);
                      }}
                      style={{
                        padding: "14px 44px 14px 44px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "normal",
                        fontFamily: "'Open Sans'",
                        color: "white",
                        background: "rgba(0,0,0)",
                        borderRadius: "30px",
                        border: "none",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      Aceptar
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      margin: "5px 0 25px 0",
                    }}
                  >
                    <h5
                      style={{
                        textAlign: "start",
                        fontSize: "24px",
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        color: "#000000",
                        margin: "0",
                      }}
                    >
                      Proceso pausado
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "26px 0px 8px 0px",
                      }}
                    >
                      <h6
                        style={{
                          textAlign: "start",
                          fontSize: "20px",
                          fontFamily: "Open Sans",
                          fontWeight: "initial",
                          color: "#000000",
                          margin: "0 0 0 0",
                          width: "150px",
                        }}
                      >
                        Acabas de pausar el proceso
                      </h6>
                      <img
                        src="https://cdn.anteia.co/images/readingBook.png"
                        alt="Caricatura de persona leyendo un libro"
                        height={"120px"}
                        width={"120px"}
                      />
                    </div>
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "initial",
                        fontSize: "18px",
                        color: "#000000",
                        textAlign: "start",
                      }}
                    >
                      Te enviamos un mensaje a tu cuenta de correo electrónico
                      en el cual encontrarás un enlace para retomar este proceso
                      en cualquier momento.
                    </p>
                  </div>
                )
              }
            />
          );
        default:
          return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };
  function getConfirmation() {
    dispatch(verifyPause({ code, pauseCode }));
  }

  useEffect(() => {
    if (pauseRedirect && pauseRedirect !== "") {
      history.replace(pauseRedirect);
    } else {
      getConfirmation();
    }
    return () => {};
  }, [pauseRedirect]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading === "pending" ? (
        <ClipLoader
          loading={loading === "pending"}
          size={150}
          color={"#FFFFFF"}
        />
      ) : (
        <div>{errorPopup()}</div>
      )}
    </div>
  );
};
