import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./DVamosIdentificar.scss";

function DVamosIdentificar(props) {
  const history = useHistory();
  
  const NextOptions= () => {
    props.redirect();
  };
  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "20vh"}}>
        <p className="title2">Vamos a verificar tu identidad <br/> antes de comenzar </p>
      </div>

   
      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="SIGUIENTE"
           onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default DVamosIdentificar;
