import React, {useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { TextField1 } from "./../InputT/TextField1/TextField1";
import "./InputT.scss";
function InputT() {
  const [error, setError] = useState(true);
  return (
    <div className="inputT">
      <div className="mini-nav">
        <p>Input</p>
      </div>
      <div className="container-box">
        <Box sx={{ flexGrow: 2 }}>
          {/* ----------------INPUT1---------------------- */}
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div>
                <p>Inactive</p>
                <TextField1 />
              </div>

              <div>
                {" "}
                <p>Focus</p>
                <TextField1 />
              </div>
            </Grid>
            {/* ----------------INPUT2---------------------- */}

            <Grid item xs={3.5}>
              <div>
                <p>Active</p>
                <TextField1 />
              </div>
              <div>
                <p>Error</p>
                <TextField1 error={error} setError={setError}/>
              </div>
            </Grid>
            {/* ----------------INPUT3---------------------- */}
            <div className="last-col">
              <p>Layout</p>
              <Grid item xs={3}>
                <div>
                  <TextField1 />
                </div>
                <div style={{marginTop: '1em'}}>
                  <TextField1 />
                  
                </div>
              </Grid>
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
export default InputT;
