import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import './TextField1.scss'
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "white",
    },
    "&:hover $notchedOutline": {
      borderColor: "white",
    },
    "&$focused $notchedOutline": {
      borderColor: "white",
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const TextField1 = (props) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );

  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [values, setValues] = useState({
    password: "",

    showPassword: false,
  });


  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 600px)").addListener(handler);
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ 
      ...values, [prop]: event.target.value });

    // if (event.target.value === "") {
    //   props.setError(true);
    // }else if(event.target.value.length > 3){
    //   setError(false)
    // }
  };

 

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <FormControl
        sx={matches ? { m: 1, width: "25ch" } : { m: 1, width: "0ch" }}
        className={classes.formControl}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password" className={props.error ? 'error-style' : ''}>Password</InputLabel>
        <OutlinedInput
          notched
          id="outlined-adornment-password"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          classes={outlinedInputClasses}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff className={props.error ? 'error-style' : ''}/> : <Visibility  className={props.error ? 'error-style' : ''}/>}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />

         {props.error ? <a style={{color:'#EA676A'}}>Error message</a> : (<a></a>)} 
      </FormControl>
    </div>
  );
};
