import React, { useState } from "react";
import "./TextField3.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
export const TextField3 = (props) => {
    const [value, setValue] = useState('female');
  const handleChange = (event) => {
    setValue(event.target.value);
    console.log('setValue', event.target.value)
  };
  return (
    <div>
      <div className="div-input-style">
        <input placeholder="label" className="input-2" />

        <Radio     onChange={handleChange}   value={value} className='radio-btn'/>
      </div>
      {props.err ? <a style={{ color: "#EA676A" }} className="error-style">Error message</a> : <a></a>}
    </div>
  );
};
export const TextField4 = (props) => {
    const [value, setValue] = useState('female');
  const handleChange = (event) => {
    setValue(event.target.value);
    console.log('setValue', event.target.value)
  };
  return (
    <div>
      <div className="div-input-style-3">
        <input placeholder="label" className="input-2" />

        <Radio     onChange={handleChange}   value={value} className='radio-btn'/>
      </div>
      {props.err ? <a style={{ color: "#EA676A" }} className="error-style">Error message</a> : <a></a>}
    </div>
  );
};
