import React from "react";
import "./ScreenLogo.scss";
import anteia from "./../../assets/minilogo.png";
function ScreenLogo() {
  return (
    <div className="logo-style">
      <p>magic forms</p>
      <img src={anteia} alt="img" />
    
   
    </div>
  );
}
export default ScreenLogo;
