import React, { useState } from "react";
import "./VideoContract.scss";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";
import { Button } from "../../utils/Buttons/Buttons";
import FormData from 'form-data';

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: { exact: "environment" }
 //facingMode: "user",
};

function VideoContract(props) {
  const history = useHistory();
  const webcamRef = React.useRef(null);
  const [image,setImage]=useState('');


  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    });
    const passFinal=()=>{
      history.push('./frmfinal'+ history.location.search);
    }

  const sendImage= async ()=>{
      const blob = await fetch(image).then((res) => res.blob());
      const formData = new FormData();
      formData.append(parseInt(props.ni), blob);      
      props.api.sendImage(formData,passFinal);
      

    }

  return (
    <div className="video">
     
        <div className="video-start">
         { <Webcam videoConstraints={videoConstraints} ref={webcamRef}/>}
         <div  className="test" style={{marginTop: "2em" }}>
        <Button text="CAPTURAR" onClick={()=>capture()} width="177px" height="19%"></Button>
        <br></br>
        {(image!="")&&<Button  text="ENVIAR" onClick={()=>sendImage()} width="177px" height="19%"></Button>}
      </div>
   
        </div>
      
    </div>
  );
}

export default VideoContract;
