import DateFnsUtils from "@date-io/date-fns/build/date-fns-utils";
import { DriveFileMove, FilePresent } from "@mui/icons-material";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { parse } from "date-fns";
import { isConditionalExpression } from "typescript";
import { infoMessage } from "../../api/errorHandler";
import {
  ExistanceConditionalConfig,
  generatePdf,
  getFromCode,
  GetFromCodeResponse,
  getRedirectionUrl,
  updateData,
  uploadFile,
  pauseFormAPI,
  PauseVerifyRequest,
  verifyPauseAPI,
  deleteFile,
} from "../api/magic_forms_2_api";
import DrawableFormTemplate from "../dtos/DrawableFormTemplate";
import FieldValidator from "../dtos/FieldValidator";
import FilledForm from "../dtos/FilledForm";
import FormConditional from "../dtos/FormConditional";
import FormField from "../dtos/FormField";
import FormPage from "../dtos/FormPage";
import LiveCalculationConfig from "../dtos/LiveCalculationConfig";
import OptionsList from "../dtos/OptionsList";
import ValidatorType from "../dtos/ValidatorType";
import { RootState } from "../store/store";

export type LoadingState = "idle" | "rejected" | "resolved" | "pending";

export interface FormState {
  numPages: number;
  currentPageNumber: number;
  filledForm?: FilledForm;
  drawableForm?: DrawableFormTemplate;
  data: { [key: string]: string };
  conditionals: { [key: string]: boolean };
  loading: boolean;
  fileUploadError: boolean;
  code: string;
  multiFields: { [key: string]: number };
  token: string;
  isDone: boolean;
  redirectionUrl?: string;
  optionsLists: { [key: string]: OptionsList };
  validations: { [key: string]: string };
  validators: { [key: string]: FieldValidator };
  existCondMap: { [key: string]: ExistanceConditionalConfig };
  errorMessage: string;
  loadingPause: LoadingState;
  loadingPauseVerify: LoadingState;
  pauseRedirect: string;
  pauseState: "None" | "Paused" | "Close";
  darkMode: boolean;
}

export interface UpdateValueAction {
  fieldId: string;
  data: string;
}

export interface UpdateFileAction {
  fieldId: string;
  file: File;
  fileName: string;
}

export interface DeleteFileAction {
  stitchTypeId: string;
}

const initialState: FormState = {
  numPages: 0,
  currentPageNumber: 0,
  data: {},
  conditionals: {},
  loading: false,
  code: "",
  multiFields: {},
  token: "",
  isDone: false,
  optionsLists: {},
  validations: {},
  validators: {},
  existCondMap: {},
  errorMessage: "",
  loadingPause: "idle",
  loadingPauseVerify: "idle",
  pauseRedirect: "",
  pauseState: "None",
  fileUploadError: false,
  darkMode: true,
};

function isPageEmpty(state: FormState): boolean {
  let conditionals = state.conditionals;
  let formPage = state.drawableForm?.formPages[state.currentPageNumber]!;
  let r = true;
  for (let key in formPage.fields) {
    let field = formPage.fields[key];

    if (!field.conditionalSourceId) return false;
    if (
      field.conditionalSourceId &&
      conditionals[field.conditionalSourceId] === true
    )
      return false;
  }
  return true;
}

function returnValidation(
  validator: FieldValidator,
  value: string,
  state: FormState
): string {
  console.log("returning validation..." + validator.type);
  // console.log(validator.type);
  let errMsg = validator.message
    ? validator.message
    : "El valor ingresado no es válido";
  //Hanlde Regex
  if (validator.type === ValidatorType.REGEX) {
    let reg = RegExp(validator.regex!!);
    let test = reg.test(value);
    // console.log(
    //   "Value " + value + " got " + test + " for regex: " + validator.regex!!
    // );
    if (!test) return errMsg;
  }
  //Handle TEXT
  if (validator.type === ValidatorType.TEXT) {
    let reg = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/;
    let test = reg.test(value);
    // console.log(
    //   "Value " + value + " got " + test + " for regex: " + validator.regex!!
    // );
    if (!test) return errMsg;
  }
  //Hanlde Email
  if (validator.type === ValidatorType.EMAIL) {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    let test = reg.test(value);
    // console.log(
    //   "Value " + value + " got " + test + " for regex: " + validator.regex!!
    // );
    if (!test) return errMsg;
  }
  //Hanlde Number
  if (validator.type === ValidatorType.NUMBER) {
    let reg = /^[0-9]*$/;
    let test = reg.test(value);
    // console.log(
    //   "Value " + value + " got " + test + " for regex: " + validator.regex!!
    // );
    if (!test) return errMsg;
  }
  if (validator.type === ValidatorType.TEXT_NUMBERS) {
    let reg = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s0-9]*$/;
    let test = reg.test(value);
    // console.log(
    //   "Value " + value + " got " + test + " for regex: " + validator.regex!!
    // );
    if (!test) return errMsg;
  }
  //Hanlde thresholds
  if (validator.type === ValidatorType.THRESHOLD_ABOVE) {
    if (value !== "") {
      try {
        let numVal = parseFloat(value);
        let thrs = validator.threshold ? validator.threshold : 0;
        if (validator.getInputFromState === true) {
          let numThrs = parseFloat(state.data[validator.inputId]);
          if (numThrs && !Number.isNaN(numThrs)) {
            thrs = numThrs;
          }
        }
        if (numVal < thrs || Number.isNaN(numVal)) return errMsg;
      } catch (e: any) {
        console.error(e);
        // return errMsg;
      }
    }
  }
  if (validator.type === ValidatorType.THRESHOLD_BELOW) {
    if (value !== "") {
      try {
        let numVal = parseFloat(value);
        let thrs = validator.threshold ? validator.threshold : 0;
        if (validator.getInputFromState === true) {
          let numThrs = parseFloat(state.data[validator.inputId]);
          if (numThrs && !Number.isNaN(numThrs)) {
            thrs = numThrs;
          }
        }
        if (numVal > thrs || Number.isNaN(numVal)) return errMsg;
      } catch (e: any) {
        console.error(e);
        // return errMsg;
      }
    }
  }
  if (validator.type === ValidatorType.BETWEEN) {
    if (value !== "") {
      try {
        let numVal = parseFloat(value);
        let thrs = validator.threshold ? validator.threshold : 0;
        let thrs2 = validator.threshold2 ? validator.threshold2 : 0;
        if (validator.getInputFromState === true) {
          let numThrs = parseFloat(state.data[validator.inputId]);
          if (numThrs && !Number.isNaN(numThrs)) {
            thrs = numThrs;
          }
          let numThrs2 = parseFloat(state.data[validator.inputId2]);
          if (numThrs2 && !Number.isNaN(numThrs2)) {
            thrs2 = numThrs2;
          }
        }
        if (Number.isNaN(numVal)) return errMsg;
        if (numVal < thrs || numVal > thrs2) return errMsg;
      } catch (e: any) {
        console.error(e);
        // return errMsg;
      }
    }
  }
  if (validator.type === ValidatorType.MULTI_SIZE) {
    try {
      console.log("MULTI SIZE VALIDATION");
      // let sizeId = validator.stitchTypeId + ".size";
      // console.log(sizeId);
      // let sizeVal = state.data[sizeId];
      // let numVal = parseFloat(sizeVal);
      let numVal = state.multiFields[validator.stitchTypeId];
      let thrs = validator.threshold ? validator.threshold : 0;
      let thrs2 = validator.threshold2 ? validator.threshold2 : 0;
      if (validator.getInputFromState === true) {
        let numThrs = parseFloat(state.data[validator.inputId]);
        if (numThrs && !Number.isNaN(numThrs)) {
          thrs = numThrs;
        }
      }
      if (Number.isNaN(numVal)) return errMsg;
      if (numVal < thrs || numVal > thrs2) return errMsg;
    } catch (e: any) {
      console.error(e);
      // return errMsg;
    }
  }
  if (validator.type === ValidatorType.MULTI_SUM) {
    try {
      console.log("validator MULTI_SUM");
      let sizeTxt = state.data[validator.stitchTypeId + ".size"] ?? "1";
      console.log("sizeTxt: " + sizeTxt);
      let size = parseFloat(sizeTxt);
      let sum = 0.0;
      //Skip if size 1 and NaN
      let skip = false;
      for (let i = 0; i < size; i++) {
        let numTxt =
          getMultiFieldByIdAndIndex(
            validator.stitchTypeId,
            validator.inputId,
            i,
            state.data
          ) ?? "0";
        let num = parseFloat(numTxt);
        skip = size === 1 && Number.isNaN(num);
        console.log("Num: " + num);
        if (Number.isNaN(num)) num = 0;
        sum += num;

        console.log("sum: " + sum);
      }
      let testValue = validator.threshold ?? 0;
      console.log("testVal: " + testValue);
      if (sum !== testValue && !skip) {
        return errMsg;
      }
    } catch (e: any) {
      console.error(e);
      return errMsg;
    }
  }
  if (validator.type === ValidatorType.VALUE_NOT_EQUALS) {
    try {
      console.log("validator VALUE NOT EQUALS");
      let testValue = state.data[validator.inputId] ?? "";
      console.log("TestValue: " + testValue);
      if (value === testValue) return errMsg;
    } catch (e: any) {
      console.error(e);
      return errMsg;
    }
  }
  switch (validator.type) {
    case ValidatorType.DATE_BEFORE_PRESENT: {
      try {
        console.log("validate date before present");
        let inputDate = parse(value, "dd/MM/yyyy", new Date());
        if (inputDate > new Date()) return errMsg;
      } catch (e: any) {
        console.error(e);
        return errMsg;
      }
      break;
    }
    case ValidatorType.DATE_AFTER_PRESENT: {
      try {
        console.log("validate date before present");
        let inputDate = parse(value, "dd/MM/yyyy", new Date());
        if (inputDate < new Date()) return errMsg;
      } catch (e: any) {
        console.error(e);
        return errMsg;
      }
      break;
    }
    case ValidatorType.DATE_IN_RANGE_BEFORE: {
      try {
        console.log("validate date in range");
        let inputDate = parse(value, "dd/MM/yyyy", new Date());
        let compareInput = state.data[validator.inputId];
        let compareDate = parse(compareInput, "dd/MM/yyyy", new Date());
        let compareDateUpper = new Date(
          compareDate.getTime() +
            1000 * 60 * 60 * 24 * (validator.threshold ?? 0)
        );
        let compareDateLower = new Date(
          compareDate.getTime() -
            1000 * 60 * 60 * 24 * (validator.threshold2 ?? 0)
        );
        if (inputDate > compareDateUpper) return errMsg;
        if (inputDate < compareDateLower) return errMsg;
      } catch (e: any) {
        console.error(e);
        return errMsg;
      }
      break;
    }
    case ValidatorType.VALUE_EQUALS: {
      try {
        console.log("validator VALUE EQUALS");
        let testValue = state.data[validator.inputId] ?? "";
        console.log("TestValue: " + testValue);
        if (value !== testValue) return errMsg;
      } catch (e: any) {
        console.error(e);
        return errMsg;
      }
      break;
    }
  }
  return "";
}

function getMultiFieldByIdAndIndex(
  multiId: string,
  subFieldId: string,
  index: number,
  data: { [key: string]: string }
): string | undefined {
  let wholeId = multiId + "[" + index + "]." + subFieldId;
  return data[wholeId];
}

/**
 * Deletes hidden fields from the data object
 * @param state
 * @returns
 */
function deleteHiddenFields(state: FormState) {
  let idsToDelete: { [key: string]: boolean } = {};
  if (state.drawableForm === undefined) return;
  state.drawableForm.formPages.forEach((formPage) => {
    for (let key in formPage.fields) {
      let field = formPage.fields[key];
      if (field.conditionalSourceId) {
        let cond = state.conditionals[field.conditionalSourceId];
        if (cond === false && idsToDelete[key] !== false) {
          idsToDelete[key] = true;
          // for(let condDest in field.conditionalDestinationId) {
          //   state.conditionals[condDest] = false;
          // }
          returnConditional(field, state, "");
        } else {
          idsToDelete[key] = false;
        }
      } else {
        idsToDelete[key] = false;
      }
    }
  });
  for (let key in idsToDelete) {
    if (idsToDelete[key] === true) {
      delete state.data[key];
    }
  }
}

function recalculateConditionals(state: FormState) {
  if (state.drawableForm === undefined) return;
  state.drawableForm.formPages.forEach((formPage) => {
    for (let key in formPage.fields) {
      let field = formPage.fields[key];
      returnConditional(field!!, state, state.data[field.stitchTypeId]);
    }
  });
}

function calculateValueLiveCalcConfig(
  conf: LiveCalculationConfig,
  stateData: { [key: string]: string },
  valueChanged: string,
  conditionals: { [key: string]: boolean }
): string | undefined {
  let r: string | undefined = "";
  let numVal = 0;
  switch (conf.type) {
    case "CONCAT": {
      let pref = "";
      conf.sourceIds.forEach((id) => {
        if (stateData[id] && stateData[id] !== "") {
          let value = stateData[id] ?? "";
          if (value.startsWith("#") && value.length > 1) {
            value = value.replace("#", "");
          }
          r += pref + value + " ";
          pref = "";
        } else {
          if (id.startsWith("{{")) {
            pref = id.replace("{{", "").replace("}}", "");
          } else {
            pref = "";
          }
        }
      });
      break;
    }

    case "ADD": {
      conf.sourceIds.forEach((id) => {
        try {
          let numToAdd = Number.parseFloat(stateData[id] ?? "0.0");
          if (!Number.isNaN(numToAdd)) numVal += numToAdd;
        } catch (e: any) {
          console.error("Error parsing data: " + stateData[id]);
          console.error(e);
        }
      });
      r = numVal.toString();
      break;
    }

    case "SUBSTRACT": {
      try {
        let first = Number.parseFloat(stateData[conf.sourceIds[0]] ?? "0");
        let second = Number.parseFloat(stateData[conf.sourceIds[1]] ?? "0");
        numVal = first - second;
      } catch (e: any) {
        console.error("Error parsing data");
        console.error(e);
      }
      r = numVal.toString();
      break;
    }
    case "MAP": {
      console.log("map");
      try {
        let datum = stateData[conf.sourceIds[0]];
        r = conf.dataMap[datum];
      } catch (e: any) {
        console.error("Error");
        console.error(e);
      }
      break;
    }
    case "MULTIPLY": {
      numVal = 1;
      conf.sourceIds.forEach((id) => {
        try {
          numVal = numVal * Number.parseFloat(stateData[id] ?? "0");
        } catch (e: any) {
          console.error("Error parsing data: " + stateData[id]);
          console.error(e);
        }
      });
      r = numVal.toString();
      break;
    }
    case "LAST_UPDATED": {
      r = valueChanged;
      break;
    }
    case "CREDIT_CONSTANT_CUOTA": {
      try {
        let amount = Number.parseFloat(stateData[conf.sourceIds[0]] ?? "0");
        let numCuotas = Number.parseFloat(stateData[conf.sourceIds[1]] ?? "0");
        let rate = Number.parseFloat(stateData[conf.sourceIds[2]] ?? "0");
        let upFraction = rate * Math.pow(1 + rate, numCuotas);
        let denom = Math.pow(1 + rate, numCuotas) - 1;

        let cuota = amount * (upFraction / denom);
        r = cuota.toFixed(0).toString();
      } catch (e: any) {
        console.error("Error");
        console.error(e);
      }
      break;
    }

    //VALUE IF CONDITIONAL
    case "VALUE_IF_CONDITIONAL": {
      let cond = conditionals[conf.conditionalId] ?? false;
      if (cond) {
        r = conf.constantValue ?? stateData[conf.sourceIds[0]] ?? "";
      } else {
        r = undefined;
      }
      break;
    }

    //DIVIDE_TO_CEIL
    case "DIVIDE_TO_CEIL": {
      try {
        let first = Number.parseFloat(stateData[conf.sourceIds[0]] ?? "0");
        let second = Number.parseFloat(stateData[conf.sourceIds[1]] ?? "1");
        if (second === 0) {
          r = "0";
          break;
        }
        numVal = first / second;
      } catch (e: any) {
        console.error("Error parsing data");
        console.error(e);
        r = "0";
      }
      r = Math.ceil(numVal).toString();
      break;
    }

    //DIVIDE_TO_FLOOR
    case "DIVIDE_TO_FLOOR": {
      try {
        let first = Number.parseFloat(stateData[conf.sourceIds[0]] ?? "0");
        let second = Number.parseFloat(stateData[conf.sourceIds[1]] ?? "1");
        if (second === 0) {
          r = "0";
          break;
        }
        numVal = first / second;
      } catch (e: any) {
        console.error("Error parsing data");
        console.error(e);
        r = "0";
      }
      r = Math.floor(numVal).toString();
      break;
    }
  }

  return r?.trim();
}

function returnConditional(
  field: FormField,
  state: FormState,
  value: string
): void {
  if (state.filledForm === undefined) return;
  console.log("returning conditional");
  console.log("conditionalDest: " + field?.conditionalDestinationId);
  let destinations = field?.conditionalDestinationId ?? [];
  for (const element of destinations) {
    let condDest = element;
    // console.log(condDest);
    // console.log(state.filledForm.conditionals[condDest]?.type);
    //if type is mapped
    if (state.filledForm.conditionals[condDest]?.type === "MAPPED") {
      let valueMapper = state.filledForm.conditionals[condDest]?.valueMapper;
      // if value is in valuemapper, set it to its value, if not, set it to false
      let newValue = valueMapper[value] ?? false;
      state.conditionals[condDest] = newValue;
      //If OVER
    } else if (state.filledForm.conditionals[condDest]?.type === "OVER") {
      let threshold =
        state.filledForm.conditionals[condDest]?.threshold ?? Number.MAX_VALUE;
      let numData = Number.parseFloat(value);
      state.conditionals[condDest] = numData > threshold;
      //IF UNDER
    } else if (state.filledForm.conditionals[condDest]?.type === "UNDER") {
      let threshold =
        state.filledForm.conditionals[condDest]?.threshold ?? Number.MIN_VALUE;
      let numData = Number.parseFloat(value);
      state.conditionals[condDest] = numData < threshold;
    } else if (state.filledForm.conditionals[condDest]?.type === "BETWEEN") {
      let thresholdLower =
        state.filledForm.conditionals[condDest]?.threshold ?? Number.MIN_VALUE;
      let thresholdUpper =
        state.filledForm.conditionals[condDest]?.threshold2 ?? Number.MAX_VALUE;
      let numData = Number.parseFloat(value);
      state.conditionals[condDest] =
        numData >= thresholdLower && numData <= thresholdUpper;
    } else if (state.filledForm.conditionals[condDest]?.type === "EXISTS") {
      console.log("EXISTS");
      let exstsId =
        state.filledForm.conditionals[condDest].existanceCondConfigId;
      let resExists = state.existCondMap[exstsId].values.indexOf(value) === -1;
      console.log(resExists);
      if (value !== "") {
        state.conditionals[condDest] = !resExists;
      }
    } else if (state.filledForm.conditionals[condDest]?.type === "NOT_EXISTS") {
      console.log("NOT_EXISTS");
      let exstsId =
        state.filledForm.conditionals[condDest].existanceCondConfigId;
      let resExists = state.existCondMap[exstsId].values.indexOf(value) === -1;
      console.log(resExists);
      if (value !== "") {
        state.conditionals[condDest] = resExists;
      }
    } else if (state.filledForm.conditionals[condDest]?.type === "NULL") {
      console.log("NULL");
      state.conditionals[condDest] = value === "";
    } else if (state.filledForm.conditionals[condDest]?.type === "NOT_NULL") {
      console.log("NOT_NULL");
      state.conditionals[condDest] = value !== "";
    }
  }
}

export const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    //setErrorAck
    setErrorAck: (state) => {
      state.fileUploadError = false;
    },
    //sets the code
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    //Adds a multi page
    addMultiPage: (state, action: PayloadAction<string>) => {
      let id = action.payload;
      state.multiFields[id]++;
      for (let k in state.drawableForm?.formPages[state.currentPageNumber]
        .fields[id].subFields) {
        if (k.includes(id + ".")) {
          let subId2 = k.replace(id, "");
          let subId = `${id}[${state.multiFields[id] - 1}]${subId2}`;
          if (!state.data[subId]) state.data[subId] = "";
        }
      }
      state.data[`${id}.size`] = state.multiFields[id].toString();
      //Execute Validator
      let validator = state.validators[id];
      let value = "";
      if (validator) {
        state.validations[id] = returnValidation(validator, value, state);
      }
      if (state.drawableForm?.extraValidators) {
        if (state.drawableForm?.extraValidators[id]?.length > 0) {
          let validators2 = state.drawableForm.extraValidators[id];
          for (let i = 0; i < validators2.length; i++) {
            let validator2 = validators2[i];
            if (state.validations[id] === "") {
              state.validations[id] = returnValidation(
                validator2,
                value,
                state
              );
            }
          }
        }
      }

      //Add subfields validators
      let f = state.drawableForm?.formPages[state.currentPageNumber].fields[id];
      console.log("Adding Validators");
      if (f) {
        for (let subKey in f?.subFields) {
          let subF = f.subFields[subKey];
          if (subF.validator) {
            let subId = subF.stitchTypeId.replace(id + ".", "");
            let wholeId = id + `[${state.multiFields[id] - 1}].` + subId;
            console.log(wholeId);
            state.validations[wholeId] = "";
            state.validators[wholeId] = subF.validator;
          }
        }
      }
    },
    //Removes a multi page
    removeMultiPage: (state, action: PayloadAction<string>) => {
      let id = action.payload;
      for (let k in state.data) {
        if (k.startsWith(id + "[" + (state.multiFields[id] - 1))) {
          delete state.data[k];
        }
      }
      if (state.multiFields[id] > 1) state.multiFields[id]--;
      state.data[`${id}.size`] = state.multiFields[id].toString();
      //Execute Validator
      let validator = state.validators[id];
      let value = "";
      if (validator) {
        state.validations[id] = returnValidation(validator, value, state);
      }
      //Extra validators
      if (state.drawableForm?.extraValidators) {
        if (state.drawableForm?.extraValidators[id]?.length > 0) {
          let validators2 = state.drawableForm.extraValidators[id];
          for (let i = 0; i < validators2.length; i++) {
            let validator2 = validators2[i];
            if (state.validations[id] === "") {
              state.validations[id] = returnValidation(
                validator2,
                value,
                state
              );
            }
          }
        }
      }
      //Delete subField validators
      let f = state.drawableForm?.formPages[state.currentPageNumber].fields[id];
      console.log("removing Validators");
      if (f) {
        for (let subKey in f?.subFields) {
          let subF = f.subFields[subKey];
          if (subF.validator) {
            let subId = subF.stitchTypeId.replace(id + ".", "");
            let wholeId = id + `[${state.multiFields[id]}].` + subId;
            console.log("Removing sub validator:");
            console.log(wholeId);
            delete state.validations[wholeId];
            delete state.validators[wholeId];
          }
        }
      }
    },
    //moves to the next page
    nextPage: (state) => {
      //Resolve validators
      for (let k in state.validators) {
        let validator = state.validators[k];
        if (validator.type === ValidatorType.MULTI_SIZE) {
          state.validations[validator.stitchTypeId] = returnValidation(
            validator,
            "",
            state
          );
        }
      }
      if (state.currentPageNumber < state.numPages - 1) {
        state.currentPageNumber++;
      }
      while (
        state.currentPageNumber < state.numPages - 1 &&
        isPageEmpty(state)
      ) {
        state.currentPageNumber++;
      }
    },
    //Moves to the previous page
    prevPage: (state) => {
      if (state.currentPageNumber > 0) state.currentPageNumber--;
      while (state.currentPageNumber > 0 && isPageEmpty(state)) {
        state.currentPageNumber--;
      }
    },

    updateValue: (state, action: PayloadAction<UpdateValueAction>) => {
      if (state.filledForm === undefined) return;
      let ac = action.payload;
      //Update value
      state.data[ac.fieldId] = ac.data;
      //Update conditionals
      let field =
        state.drawableForm?.formPages[state.currentPageNumber].fields[
          ac.fieldId
        ];

      //Check if field affects live calc stuff
      let liveConfigs = state.drawableForm?.liveCalculationConfigs ?? [];
      let destIds = [""];
      for (let i = 0; i < liveConfigs.length; i++) {
        let conf = liveConfigs[i];

        if (conf.sourceIds.indexOf(ac.fieldId) !== -1) {
          let newVal = calculateValueLiveCalcConfig(
            conf,
            state.data,
            ac.data,
            state.conditionals
          );
          console.log("New Value:");
          console.log(newVal);
          console.log(conf.destId);
          if (newVal) {
            state.data[conf.destId] = newVal;
            destIds.push(conf.destId);
          }
        } else if (conf.type !== "LAST_UPDATED") {
          let newVal = calculateValueLiveCalcConfig(
            conf,
            state.data,
            "",
            state.conditionals
          );
          if (newVal) {
            console.log(destIds);
            console.log(newVal);
            console.log(conf.destId);
            //Si no se ha aplicado un live config a ese dest
            if (destIds.indexOf(conf.destId) === -1) {
              state.data[conf.destId] = newVal;
            }
          }
        }
      }
      returnConditional(field!!, state, ac.data);

      //Execute Validator
      let validator = state.validators[ac.fieldId];
      let value = ac.data;
      if (validator) {
        state.validations[ac.fieldId] = returnValidation(
          validator,
          value,
          state
        );
      }
      //Extra validator
      let id = ac.fieldId;
      if (state.drawableForm?.extraValidators) {
        console.log("1");
        console.log(id);
        if (state.drawableForm?.extraValidators[id]?.length > 0) {
          console.log("2");
          let validators2 = state.drawableForm.extraValidators[id];
          for (let i = 0; i < validators2.length; i++) {
            console.log("3");
            let validator2 = validators2[i];
            if (state.validations[id] === "") {
              console.log("aaaa");
              state.validations[id] = returnValidation(
                validator2,
                value,
                state
              );
            }
          }
        }
      }
      if (ac.fieldId.indexOf("[") !== -1) {
        let multiId = ac.fieldId.split("[")[0];
        validator = state.validators[multiId];
        if (validator) {
          state.validations[multiId] = returnValidation(
            validator,
            value,
            state
          );
        }
        if (state.drawableForm?.extraValidators) {
          console.log("1");
          console.log(multiId);
          if (state.drawableForm?.extraValidators[multiId]?.length > 0) {
            console.log("2");
            let validators2 = state.drawableForm.extraValidators[multiId];
            for (let i = 0; i < validators2.length; i++) {
              console.log("3");
              let validator2 = validators2[i];
              if (state.validations[multiId] === "") {
                console.log("aaaa");
                state.validations[multiId] = returnValidation(
                  validator2,
                  value,
                  state
                );
              }
            }
          }
        }
      }
      deleteHiddenFields(state);
      // recalculateConditionals(state);
    },
    // addFile: (state, action: PayloadAction<UpdateFileAction>) => {},
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    changePauseState: (
      state,
      action: PayloadAction<"None" | "Paused" | "Close">
    ) => {
      state.pauseState = action.payload;
    },
    resetGenPath: (state) => {
      state.pauseRedirect = "";
    },
  },
  extraReducers: (builder) => {
    //get code success
    builder.addCase(tryGetByCode.fulfilled, (state, action) => {
      //set loading
      state.loading = false;
      if (action.payload.redirectionUrl) {
        state.redirectionUrl = action.payload.redirectionUrl;
        state.errorMessage = "Form Done";
        state.token = action.payload.token;
      } else {
        //Set Token
        state.token = action.payload.token;
        //set filledForm
        let resp = action.payload as GetFromCodeResponse;
        state.filledForm = resp.filledForm as FilledForm;
        //set drawable form
        state.drawableForm = action.payload.drawableFormTemplate;
        //set data
        state.data = action.payload.filledForm.data;
        for (let k in state.data) {
          if (!state.data[k]) state.data[k] = "";
        }
        //set conditionals
        let filledForm = resp.filledForm as FilledForm;
        let conds = filledForm.conditionals as {
          [key: string]: FormConditional;
        };
        for (let condKey in conds) {
          state.conditionals[condKey] = conds[condKey].status;
        }
        //num pages
        state.numPages = action.payload.drawableFormTemplate.formPages.length;
        state.currentPageNumber = action.payload.filledForm.pageNumber;

        //delete lables from data and add multi
        state.drawableForm?.formPages.forEach((p) => {
          for (let key in p.fields) {
            let f = p.fields[key];
            //Handle labels and multis
            if (f.type === "label") {
              delete state.data[f.stitchTypeId];
            }
            if (f.type === "multi") {
              delete state.data[f.stitchTypeId];
              state.multiFields[f.stitchTypeId] = 1;
              //handle multi validators
              for (let subKey in f.subFields) {
                let subF = f.subFields[subKey];
                if (subF.validator) {
                  let subId = subF.stitchTypeId.replace(key + ".", "");
                  let wholeId = key + "[0]." + subId;
                  state.validations[wholeId] = "";
                  state.validators[wholeId] = subF.validator;
                }
              }
            }
            //Handle validators
            state.validations[key] = "";
            if (f.validator) {
              state.validators[key] = f.validator;
            }
          }
        });

        //Add multi fields
        console.log("AddMultiFields");
        let ids = Object.keys(state.multiFields);
        for (let k in state.data) {
          for (let a = 0; a < ids.length; a++) {
            let id = ids[a];
            if (k.startsWith(id + ".")) {
              delete state.data[k];
              let subId2 = k.replace(id, "");
              let subId = `${id}[0]${subId2}`;
              if (!state.data[subId]) state.data[subId] = "";
            }
          }
        }
        // Add optionsLists map
        state.optionsLists = action.payload.optionsLists;
        state.existCondMap = action.payload.existanceConditionalConfigs;

        //Reload conditionals
        state.drawableForm.formPages.forEach((fp) => {
          for (let k in fp.fields) {
            let field = fp.fields[k];
            if (field) {
              if (
                field.conditionalDestinationId.length > 0 &&
                state.data[k] &&
                state.data[k] !== ""
              ) {
                console.log("Loading cond data for field " + k);
                returnConditional(field, state, state.data[k]);
              }
            }
          }
        });
      }
    });
    //get code pending
    builder.addCase(tryGetByCode.pending, (state) => {
      state.loading = true;
    });
    //get code error
    builder.addCase(tryGetByCode.rejected, (state, action) => {
      state.loading = false;
      console.log("Error getting");
      let error = action.payload as GetFromCodeResponse;
      console.log(error.errorMessage);
      state.errorMessage = action.payload as string;
      //infoMessage("Error: El url está expirado");
    });

    // Pause Flow
    builder
      .addCase(tryPause.fulfilled, (state) => {
        state.loadingPause = "resolved";
        state.pauseState = "Paused";
      })
      .addCase(tryPause.pending, (state) => {
        state.loadingPause = "pending";
      })
      .addCase(tryPause.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loadingPause = "resolved";
      });

    builder
      .addCase(verifyPause.fulfilled, (state, action) => {
        state.pauseRedirect = action.payload.genPath;
        state.loadingPauseVerify = "resolved";
      })
      .addCase(verifyPause.pending, (state) => {
        state.loadingPauseVerify = "pending";
      })
      .addCase(verifyPause.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loadingPauseVerify = "resolved";
      });

    //Update success
    builder.addCase(tryUpdate.fulfilled, (state, action) => {
      console.log("try update success!!!");
      // state.loading = false;
      state.redirectionUrl = action.payload.url;
      state.isDone = true;
    });
    //update pending
    builder.addCase(tryUpdate.pending, (state) => {
      state.loading = true;
    });
    //update error
    builder.addCase(tryUpdate.rejected, (state, action) => {
      state.loading = false;
      console.log("Error updating");
      let error = action.payload as GetFromCodeResponse;
      console.log(error.errorMessage);
    });
    //Upload File....
    //Success
    builder.addCase(tryUploadFile.fulfilled, (state, action) => {
      console.log("try upload file success!!!");
      state.loading = false;
    });
    //pending
    builder.addCase(tryUploadFile.pending, (state) => {
      state.loading = true;
    });
    //error
    builder.addCase(tryUploadFile.rejected, (state, action) => {
      state.loading = false;
      console.log(action.payload);
      state.fileUploadError = true;
      state.data[action.payload as string] = "";
      console.log("Error uploading file");
      let error = action.payload as GetFromCodeResponse;
      console.log(error.errorMessage);
    });
    //Select Last Button
    builder.addCase(selectLastButton.fulfilled, (state, action) => {
      console.log("fullfiled");
      console.log(action.payload);
      state.data[action.payload.fieldId] = action.payload.data;
    });
    //Delete File
    builder.addCase(tryDeleteFile.fulfilled, (state, action) => {
      console.log("try delete file success!!!");
      state.data[action.meta.arg.stitchTypeId] = "";
      state.loading = false;
    });
    builder.addCase(tryDeleteFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(tryDeleteFile.rejected, (state, action) => {
      state.loading = false;
      console.log("Error deleting file");
      let error = action.payload as GetFromCodeResponse;
      console.log(error.errorMessage);
    });
  },
});

export const tryGetByCode = createAsyncThunk(
  "form/tryGetByCode",
  async (code: string, { getState, rejectWithValue }) => {
    let resp = await getFromCode(code);

    if (resp.errorMessage) {
      return rejectWithValue(resp.errorMessage);
    } else {
      return resp;
    }
  }
);

export const selectLastButton = createAsyncThunk(
  "form/selectLastButton",
  async (ac: UpdateValueAction, { getState, rejectWithValue }) => {
    console.log("AsyncThunk");
    console.log(ac);
    return ac;
  }
);

export const tryPause = createAsyncThunk(
  "form/pauseCode",
  async (code: string, { rejectWithValue }) => {
    let data = await pauseFormAPI(code);
    if (data.error) {
      return rejectWithValue(data.error);
    } else {
      return data;
    }
  }
);

export const verifyPause = createAsyncThunk(
  "form/verifyPause",
  async (req: PauseVerifyRequest, { rejectWithValue }) => {
    let data = await verifyPauseAPI(req);
    if (data.error) {
      return rejectWithValue(data.error);
    } else {
      return data;
    }
  }
);

export const updateParcial = createAsyncThunk(
  "form/updateParcial",
  async (_, { getState }) => {
    let state = getState() as RootState;
    await updateData(
      state.form.data,
      state.form.conditionals,
      state.form.currentPageNumber,
      state.form.token
    );
  }
);

export const tryUpdate = createAsyncThunk(
  "form/tryUpdate",
  async (_, { getState, rejectWithValue }) => {
    try {
      let state = getState() as RootState;
      let resp = await updateData(
        state.form.data,
        state.form.conditionals,
        state.form.currentPageNumber,
        state.form.token
      );

      if (resp.errorMessage) {
        return rejectWithValue(resp.errorMessage);
      }

      let respGenPdf = await generatePdf(state.form.token);
      if (respGenPdf.errorMessage) {
        return rejectWithValue(respGenPdf.errorMessage);
      }

      let respRedirection = await getRedirectionUrl(state.form.token);
      if (respRedirection.errorMessage) {
        return rejectWithValue(respRedirection.errorMessage);
      }

      return respRedirection;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) =>
      reject(Error("Error converting to base64 " + error.eventPhase));
  });

export const tryUploadFile = createAsyncThunk(
  "form/tryUploadFile",
  async (action: UpdateFileAction, { getState, rejectWithValue }) => {
    try {
      let state = getState() as RootState;
      let b64 = await toBase64(action.file);
      let b64_2 = b64.split(",")[1];
      let req = {
        fileBase64: b64_2,
        fileName: action.fileName,
        stitchTypeId: action.fieldId,
      };
      let resp = await uploadFile(req, state.form.token);

      if (resp.errorMessage) {
        return rejectWithValue(action.fieldId);
      } else {
        return resp;
      }
    } catch (err) {
      return rejectWithValue(action.fieldId);
    }
  }
);

export const tryDeleteFile = createAsyncThunk(
  "form/tryDeleteFile",
  async (action: DeleteFileAction, { getState, rejectWithValue }) => {
    try {
      let state = getState() as RootState;
      let resp = await deleteFile(action.stitchTypeId, state.form.token);

      if (resp.errorMessage) {
        return rejectWithValue(action.stitchTypeId);
      } else {
        return resp;
      }
    } catch (err) {
      return rejectWithValue(action.stitchTypeId);
    }
  }
);

//Export selects
export const selectCurrentPageNumber = (state: RootState) =>
  state.form.currentPageNumber;
export const selectFilledForm = (state: RootState) => state.form.filledForm;
export const selectDrawableForm = (state: RootState) => state.form.drawableForm;
export const selectConditionals = (state: RootState) => state.form.conditionals;
export const selectData = (state: RootState) => state.form.data;
export const selectNumPages = (state: RootState) => state.form.numPages;
export const selectLoading = (state: RootState) => state.form.loading;
export const selectMultifields = (state: RootState) => state.form.multiFields;
export const selectIsDone = (state: RootState) => state.form.isDone;
export const selectRedirectionUrl = (state: RootState) =>
  state.form.redirectionUrl;
export const selectLoadingPause = (state: RootState) => state.form.loadingPause;
export const selectErrorMessage = (state: RootState) => state.form.errorMessage;
export const selectLoadingPauseVerify = (state: RootState) =>
  state.form.loadingPauseVerify;
export const selectPauseRedirect = (state: RootState) =>
  state.form.pauseRedirect;
export const selectPauseState = (state: RootState) => state.form.pauseState;
export const selectUploadError = (state: RootState) =>
  state.form.fileUploadError;
export const selectOptionsLists = (state: RootState) => state.form.optionsLists;
export const selectValidations = (state: RootState) => state.form.validations;
export const selectToken = (state: RootState) => state.form.token;
export const selectDarkMode = (state: RootState) => state.form.darkMode;
//Export selects
export const {
  nextPage,
  prevPage,
  updateValue,
  setCode,
  addMultiPage,
  removeMultiPage,
  resetErrorMessage,
  changePauseState,
  resetGenPath,
  setErrorAck,
} = formSlice.actions;

//Export the reducer
export default formSlice.reducer;
