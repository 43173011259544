import React from "react";
import "./Tecno.scss";

// import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
  },
  check: {
    color: "white",
    "&$checked": {
      color: "white",
    }
  }
}));
function Tecno() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);

  let history = useHistory();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const NextReq = () => {
    history.push("./requirement"+ history.location.search);
  };
  return (
    <div className="tecno-style">
      {/* -------------------------ROW WITH CHECK 1---------------------------------- */}
      <div className="container1">
        <div className="row-title1">
          <p className="p1">TECNOMECÁNICA</p>
          <p className="p2">Revisión Técnico Mecánica</p>
        </div>
        <div className={classes.root}>
          <div  className="row-1">

        
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <p className="p1">Vigente</p>
              <p className="p2">SI</p>
            </Grid>
            <Grid item xs={4}>
              <p className="p1">Nro. certificado</p>
              <p className="p2"> 144766580</p>
            </Grid>
            <Grid item xs={4}>
             <div style={{marginTop:'1.2em'}}>
             <Checkbox
                className={classes.check}
                checked={checked}
                onChange={handleChange}
                color="default"
              />
             </div>
           
            </Grid>
          </Grid>
          </div>
        </div>
     
      </div>
      {/* -------------------------ROW WITH CHECK 2---------------------------------- */}
      <div className="container1">
        <div className="row-title1">
          <p className="p1">SOAT</p>
          <p className="p2"> Seguro Obligatorio de Accidentes de Tránsito</p>
        </div>
        <div className={classes.root} >
          <div className="row-1">
          <Grid container spacing={3}>
            <Grid item xs={4}>
            <p className="p1">Vigente</p>
            <p className="p2">SI</p>
            </Grid>
            <Grid item xs={4}>
            <p className="p1">Nro. certificado</p>
            <p className="p2">144766580</p>
            </Grid>
            <Grid item xs={4}>
             <div style={{marginTop:'1.2em'}}>
             <Checkbox
                className={classes.check}
                checked={checked}
                onChange={handleChange}
                color="default"
              />
             </div>
           
            </Grid>
          </Grid>
          </div>
        </div>
       
      </div>

      {/* -------------------------ROW WITH CHECK 3---------------------------------- */}
      <div className="container2">
        <div className="row-title2">
          <p className="p1">MULTAS</p>
          <p className="p2">
            Estado de Cuenta sobre las Multas y Sanciones<br></br>
            por infracciones de tránsito
          </p>
        </div>

    



        <div className="row-2">
          <div>
            <div className='texts'>
            <p className="p1">
              El (la) señor(a) identificado(a) con Cedula No. 79798417<br></br>
              (SIETE NUEVE SIETE NUEVE OCHO CUATRO UNO SIETE),
            </p>
            <p className="p1">
              <span>NO POSEE</span> a la fecha pendientes de pago registrados en
              Simit por concepto de Multas y Sanciones por Infracciones de
              Tránsito, en los Organismos fde Tránsito conectados al sistema.
              <br></br>
             
            </p>
            </div>
         
            <div className={classes.root} >
              <div className="row-1">

              
          <Grid container spacing={3}>
            <Grid item xs={9}>
           <p className="p1">Expedición: 10 de Agosto de 2021 a las 8:30 AM</p> 
            </Grid>
            
            <Grid item xs={1}>
             <div>
             <Checkbox
                className={classes.check}
                checked={checked}
                onChange={handleChange}
                color="default"
              />
             </div>
           
            </Grid>
          </Grid>
          </div>
        </div>
          </div>
        </div>

      </div>
      {/* -------------------------ROW WITH CHECK 4---------------------------------- */}
      <div className="container4">
        <div className="row-title4">
          <p className="p1">IMPUESTOS</p>
          <p className="p2">Estado de Cuenta sobre impuestos vehículo</p>
        </div>

        <div className={classes.root} >
          <div className="row-4">

       
          <Grid container spacing={3}>
            <Grid item xs={8}>
            <p className="p2">AL DÍA</p>
            </Grid>
         
            <Grid item xs={4}>
             <div>
             <Checkbox
                className={classes.check}
                checked={checked}
                onChange={handleChange}
                color="default"
              />
             </div>
           
            </Grid>
          </Grid>
          </div>
        </div>
   
      </div>
      {/* -------------------------ROW WITH CHECK 5---------------------------------- */}
      <div className="container4">
        <div className="row-title4">
          <p className="p1" style={{ lineHeight: "20px" }}>
            LIMITACIÓNES<br></br>A LA PROPIEDAD
          </p>
        </div>
        <div className={classes.root} >
          <div className="row-4">

        
          <Grid container spacing={3}>
            <Grid item xs={8}>
            <p className="p2">NINGUNA</p>
            </Grid>
         
            <Grid item xs={4}>
             <div>
             <Checkbox
                className={classes.check}
                checked={checked}
                onChange={handleChange}
                color="default"
              />
             </div>
           
            </Grid>
          </Grid>
          </div>
        </div>
    
      </div>

      <div style={{ margin: "2.5em" }}>
        {" "}
        <Button
          text="SIGUIENTE"
          onClick={() => NextReq()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default Tecno;
