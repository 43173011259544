import QRCode from "qrcode";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ClientUserData,
  createAsesorAPI,
  CreateAsesorRequest,
  getAsesorAPI,
  getAsesorBrandConfigAPI,
  getAsesorPicAPI,
} from "../hyperFlow/hyperFlowApi";
import { RootState } from "../magic_forms_2/store/store";
import { AttachedFile, sendQRAsesor } from "./asesorAPI";

export interface AsesorState {
  brandConfig: BrandConfig;
  asesor: ClientUserData;
  loadingAsesor: LoadingStatus;
  loadingCreateAsesor: LoadingStatus;
  asesorCreateRequest: CreateAsesorRequest;
  generatedURlForQR: string;
  clientUserId: string;
  asesorPicBase64: string;
  loadingSendEmail: LoadingStatus;
  loadingAsesorPic: LoadingStatus;
  loadingBrandConfig: LoadingStatus;
}

export type LoadingStatus = "idle" | "pending" | "resolved" | "rejected";

export interface BrandConfig {
  brandColor: string;
  brandColor2: string;
  brandLogoUrl: string;
  buttonColor?: string;
  buttonTextColor?: string;
  textColor?: string;
}

const initialState: AsesorState = {
  brandConfig: {
    brandColor: "#013b69",
    brandColor2: "#000e1a",
    brandLogoUrl: "https://cdn.anteia.co/logos/anteia_white_transparent.png",
    buttonColor:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);",
    buttonTextColor: "#ffffff",
    textColor: "#ffffff",
  },
  asesor: {
    active: false,
    asesorData: {
      cargo: "",
      codAsesor: "",
      direccion: "",
      oficina: "",
      photoUrl: "",
    },
    clientId: "",
    email: "",
    esAsesor: false,
    id: "",
    identification: "",
    name: "",
    phoneNumber: "",
    userId: "",
  },

  loadingAsesor: "idle",
  loadingCreateAsesor: "idle",
  generatedURlForQR: "",
  asesorCreateRequest: {
    cargo: "",
    codAsesor: "",
    code: "",
    direccion: "",
    oficina: "",
    photoBase64: "",
    registrationId: "",
  },
  clientUserId: "",
  loadingSendEmail: "idle",
  asesorPicBase64: "",
  loadingAsesorPic: "idle",
  loadingBrandConfig: "idle",
};

export const createAsesor = createAsyncThunk(
  "asesor/createAsesor",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const asesorCreateRequest = state.asesor.asesorCreateRequest;
    const response = await createAsesorAPI(asesorCreateRequest);
    if (response.error) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const getAsesorInfo = createAsyncThunk(
  "asesor/getAsesorInfo",
  async (asesorId: string, { rejectWithValue }) => {
    const response = await getAsesorAPI(asesorId);
    if (response.error) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const getAsesorBrandConfig = createAsyncThunk(
  "asesor/getAsesorBrandConfig",
  async (asesorId: string, { rejectWithValue }) => {
    const response = await getAsesorBrandConfigAPI(asesorId);
    if (response.error || response.brandConfigResponse === null) {
      return rejectWithValue(response.error);
    }
    return response.brandConfigResponse;
  }
);

export const getAsesorPic = createAsyncThunk(
  "asesor/getAsesorPic",
  async (asesorId: string, { rejectWithValue }) => {
    const response = await getAsesorPicAPI(asesorId);
    if (response.error) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const sendEmail = createAsyncThunk(
  "asesor/sendEmail",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const registrationId = state.asesor.asesorCreateRequest.registrationId;
    const generatedURlForQR = state.asesor.generatedURlForQR;
    const qrCodeDataUrl = await QRCode.toDataURL(generatedURlForQR, {
      width: 400,
    });
    let attachedFiles: AttachedFile[] = [];

    if (qrCodeDataUrl.includes("data:image/png;base64,")) {
      let attachedFile: AttachedFile = {
        base64Content: qrCodeDataUrl.split("data:image/png;base64,")[1],
        fileName: "QRCodeAsesor.png",
        mimeType: "image/png",
      };
      attachedFiles.push(attachedFile);
    }

    const response = await sendQRAsesor({
      registrationId: registrationId,
      content: "",
      subject: "",
      templateData: {
        bannerTitle: "Tu código de Asesor",
        title: "Bien hecho! Tu código QR ha sido creado",
        paragraph1:
          "Hemos generado un código QR para que puedas compartirlo con tus clientes",
        paragraph2:
          "Tu código QR está en forma de imagen adjunto a este correo, puedes descargarlo, imprimirlo, incrustarlo en tus redes sociales o simplemente compartirlo con tus clientes",
      },
      attachedFiles,
    });
    if (response.result) {
      return response;
    } else {
      return rejectWithValue("error sending email");
    }
  }
);

export const asesorSlice = createSlice({
  name: "asesor",
  initialState,
  reducers: {
    changeCreateAsesorRequest: (state, action) => {
      state.asesorCreateRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    // create asesor
    builder
      .addCase(createAsesor.pending, (state) => {
        state.loadingCreateAsesor = "pending";
      })
      .addCase(createAsesor.fulfilled, (state, action) => {
        if (action.payload.asesorResponse) {
          state.generatedURlForQR = action.payload.asesorResponse.url;
          state.clientUserId = action.payload.asesorResponse.clientUserId;
        }
        state.loadingCreateAsesor = "resolved";
      })
      .addCase(createAsesor.rejected, (state) => {
        state.loadingCreateAsesor = "rejected";
      });

    // sending email
    builder
      .addCase(sendEmail.pending, (state) => {
        state.loadingSendEmail = "pending";
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.loadingSendEmail = "resolved";
      })
      .addCase(sendEmail.rejected, (state) => {
        state.loadingSendEmail = "rejected";
      });

    // getAsesor

    builder
      .addCase(getAsesorInfo.pending, (state) => {
        state.loadingAsesor = "pending";
      })
      .addCase(getAsesorInfo.fulfilled, (state, action) => {
        state.loadingAsesor = "resolved";
        state.asesor = action.payload.asesorResponse!;
      })
      .addCase(getAsesorInfo.rejected, (state) => {
        state.loadingAsesor = "rejected";
      });

    // getAsesorPic
    builder
      .addCase(getAsesorPic.pending, (state) => {
        state.loadingAsesorPic = "pending";
      })
      .addCase(getAsesorPic.rejected, (state) => {
        state.loadingAsesorPic = "rejected";
      })
      .addCase(getAsesorPic.fulfilled, (state, action) => {
        state.loadingAsesorPic = "resolved";
        state.asesorPicBase64 = action.payload.asesorResponse?.imgBase64!;
      });

    // asesorBrandConfig
    builder
      .addCase(getAsesorBrandConfig.pending, (state) => {
        state.loadingBrandConfig = "pending";
      })
      .addCase(getAsesorBrandConfig.rejected, (state) => {
        state.loadingBrandConfig = "rejected";
      })
      .addCase(getAsesorBrandConfig.fulfilled, (state, action) => {
        state.loadingBrandConfig = "resolved";
        state.brandConfig = {
          brandColor: action.payload.backgroundColor1,
          brandColor2: action.payload.backgroundColor2,
          brandLogoUrl: action.payload.logoUrl,
        };
      });
  },
});

export const { changeCreateAsesorRequest } = asesorSlice.actions;

export const selectAsesor = (state: RootState) => state.asesor.asesor;
export const selectBrandConfig = (state: RootState) => state.asesor.brandConfig;
export const selectLoadingAsesor = (state: RootState) =>
  state.asesor.loadingAsesor;

export const selectAsesorCreateRequest = (state: RootState) =>
  state.asesor.asesorCreateRequest;
export const selectGeneratedURlForQR = (state: RootState) =>
  state.asesor.generatedURlForQR;
export const selectLoadingCreateAsesor = (state: RootState) =>
  state.asesor.loadingCreateAsesor;
export const selectClientUserId = (state: RootState) =>
  state.asesor.clientUserId;
export const selectLoadingSendEmail = (state: RootState) =>
  state.asesor.loadingSendEmail;
export const selectAsesorPic = (state: RootState) =>
  state.asesor.asesorPicBase64;
export const selectAsesorPicLoading = (state: RootState) =>
  state.asesor.loadingAsesorPic;
export const selectLoadingBrandConfig = (state: RootState) =>
  state.asesor.loadingBrandConfig;
export default asesorSlice.reducer;
