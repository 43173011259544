import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { Button2, ButtonStyle } from "./../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./InsScreen1.scss";

function InsScreen1() {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const NextOptions= () => {
    history.push('./ins2'+ history.location.search)
  };
  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "4em"}}>
        <p className="title1">Seguro Viajero</p>
        <p className="title2">Ingreso a Costa Rica</p>
        <Radio
          style={{ color: "#CBDACF" }}
          checked={selectedValue === "a"}
          onChange={handleChange}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 30,
            },
          }}
        />
      </div>

      <div className="box_one">
        <p className="title1">Seguro Viajero</p>
        <p className="title2">Salida de Costa Rica</p>
        <Radio
          style={{ color: "#CBDACF" }}
          checked={selectedValue === "b"}
          onChange={handleChange}
          value="b"
          name="radio-buttons"
          inputProps={{ "aria-label": "B" }}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 30,
            },
          }}
        />
      </div>
      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="SIGUIENTE"
           onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default InsScreen1;
