import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import "./DestinyScreen.scss";
import { Button2, ButtonStyle } from "../../utils/Buttons/Buttons";
import { TextFieldStyle3 } from "./../../utils/TextFieldStyle/TextFieldStyle";
import DatePicker from 'react-date-picker';
import {useHistory} from 'react-router-dom'
  
function DestinyScreen(props) {
  let history= useHistory();
  const [selectedValue, setSelectedValue] = useState("a");
 const [value, onChange] = useState(new Date());
 const [value2, onChange2] = useState(new Date());
 
 const userChange = (name, value) => {
              var temp=props.req;
              temp[name]=value;
              props.onChangeReq(temp);
};


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    var value="basico";
    if(event.target.value=="b"){
      value="completo";
    }
    userChange("type_plan", value);
  };
  const NextOptions= () => {
    history.push('./slider1'+ history.location.search)
  };
  return (
    <div className="container-destiny">
      <div className="container-inputs">
        <div style={{ marginTop: "3em" }}>
          <TextFieldStyle3
            label="Destino de tu viaje"
            onChange={(e) => {
              userChange("destino", e.target.value);
            }}
          />
        </div>
        {/* <div style={{ marginTop: "3em" }}>
          <TextFieldStyle3
            label="¿ Cuándo iniciará tu viaje ?"
            onChange={(e) => {
              console.log(e.target.value);
            }}
          />
        </div> */}

        <div style={{ marginTop: "2em" }}>
          <p className="title-picker">¿ Cuándo iniciará tu viaje ?</p>
          <DatePicker format="y-MM-dd" onChange={(e) => {
            
          onChange(e);
          userChange("start_date", e);
          
          }} value={value} style={{width:'4em', color: "white"}}/>
        </div>
        <div style={{ marginTop: "2em" }}>
          <p className="title-picker">¿ Cuándo finalizará tu viaje ?</p>
          <DatePicker format="y-MM-dd" onChange={(e) => {
            
            onChange2(e);
            console.log(e);
            userChange("end_date", e);
            
            }} value={value2} style={{color: "white"}}/>
        </div>
        {/* <div style={{ marginTop: "3em" }}>
          <TextFieldStyle3
            label="¿ Cuándo finalizará tu viaje ?"
            onChange={(e) => {
              console.log(e.target.value);
            }}
          />
        </div> */}
      </div>
      <p className="plan">Plan de aseguramiento</p>
      <div className="plan-square">
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={7}>
              <p style={{ marginRight: "10%" }}>Plan Básico</p>
            </Grid>
            <Grid
              item
              xs={5}
              style={{ textAlignLast: "left", marginTop: "3%" }}
            >
              {" "}
              <Radio
                style={{ color: "#CBDACF" }}
                checked={selectedValue === "a"}
                onChange={handleChange}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 30,
                  },
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <p>Plan Completo</p>
            </Grid>
            <Grid
              item
              xs={5}
              style={{ textAlignLast: "left", marginTop: "3%" }}
            >
              {" "}
              <Radio
                style={{ color: "#CBDACF" }}
                checked={selectedValue === "b"}
                onChange={handleChange}
                value="b"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 30,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div style={{ margin: "2.4em" }}>
        <ButtonStyle
          text="SIGUIENTE"
          onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default DestinyScreen;
