import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./MFinalCuentaAhorros.scss";

function MFinalCuentaAhorros() {
  const history = useHistory();
  
  const NextOptions= () => {
    history.push('./coociquedata'+ history.location.search)
  };
  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "20vh"}}>
        <p className="title1">¡Excelente!</p>
        <p className="title2">Se ha abierto tu cuenta <br/> de forma exitosa</p>
      </div>

   
     
    </div>
  );
}
export default MFinalCuentaAhorros;
