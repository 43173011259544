import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { Button2, ButtonIns, ButtonStyle } from "./../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import logoIns from "../../assets/logoins.png";
import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";

import "./ins_new.scss";

function InsForm1() {
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState("a");

  const api = new DummiesApi();
  const query = useQuery();
  const id = query.get("filledFormId");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const NextOptions = () => {
    history.push("./insForm2" + history.location.search);
  };
  return (
    <div
      className="screen1"
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "4em" }}
      >
        <img src={logoIns}></img>
        <p className="title1">Seguro Viajero</p>
        <p className="title2">Ingreso a Costa Rica</p>
        <Radio
          style={{ color: "#CBDACF" }}
          checked={selectedValue === "a"}
          onChange={handleChange}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 30,
            },
          }}
        />
      </div>

      <div className="box_one">
        <p className="title1">Seguro Viajero</p>
        <p className="title2">Salida de Costa Rica</p>
        <Radio
          style={{ color: "#CBDACF" }}
          checked={selectedValue === "b"}
          onChange={handleChange}
          value="b"
          name="radio-buttons"
          inputProps={{ "aria-label": "B" }}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 30,
            },
          }}
        />
      </div>
      <div style={{ marginTop: "3em", marginBottom: "7vh" }}>
        <ButtonStyle
          text="Siguiente"
          onClick={() => NextOptions()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default InsForm1;
