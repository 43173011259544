import React from "react";
import './TextField2.scss'
export const TextField2 = (props) => {
  return (
    <div>
      <div className="div-input-style">
<input placeholder="label"/>

      </div>
      {props.errorInput ? <a style={{color:'#EA676A'}}>Error message</a> : (<a></a>)} 

    </div>

  );
};
