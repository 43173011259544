import axios from "axios";

//const server = "http://localhost:8087";
const server = "https://services.anteia.co";
// const server = "https://magicforms-transact.dev.api.anteia.co/";

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

export const sendQRAsesor = async (req: SendEmailRequest) => {
  const response = await axios.post<{ result: boolean }>(
    `${server}/asesor/sendQR`,
    req
  );
  return response.data;
};

export interface SendEmailRequest {
  registrationId: string;
  subject: string;
  content: string;
  attachedFiles: AttachedFile[];
  templateData: TemplateEmailData;
}

export interface AttachedFile {
  fileName: string;
  mimeType: string;
  base64Content: string;
}

export interface TemplateEmailData {
  bannerTitle?: string;
  title?: string;
  paragraph1?: string;
  paragraph2?: string;
  type?: string;
  logoUrl?: string;
  urlButton?: string;
  buttonText?: string;
}
