import React from 'react'
import './ScreenSuccess.scss'
import img from './../../assets/Group.png'
import {Button} from './../../utils/Buttons/Buttons'
import {useHistory} from 'react-router-dom'
function ScreenSuccess({tomaroute}){
    const history = useHistory()

const nextToma=()=>{
    // history.push('./toma2'+ history.location.search)
    history.push(tomaroute+ history.location.search)
}


    return(
        <div className='screen-success'>
<img src={img} alt='img'/>
<p>El video <br></br>ha sido guardado<br></br>satisfactoriamente</p>
<div style={{marginTop: "10em" }}>
<Button text='CONTINUAR'onClick={() =>nextToma()} width="177px" height="4vh"/>
</div>

        </div>
    )
}

export default ScreenSuccess;