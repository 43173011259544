import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import {useHistory} from 'react-router-dom'


import "./WVisualizar.scss";

function WVisualizar(props) {
  const history = useHistory();
  
  const NextOptions1= () => {
     console.log(props.data);
    history.push('./VAdjunta'+ history.location.search)
  };
  const NextOptions2= () => {
     
    history.push('./XVerifiquemosCredito'+ history.location.search)
  };
  


  return (
    <div className="screen1">
      <div className="box_one" style={{marginBottom:'2em', marginTop: "10%"}}>
{(props.data.type==="file")&& <p>
{props.data.value}

      </p>}
      {(props.data.type==="img")&& <img src={props.data.value} alt="document"/>}

      </div>

   
      <div style={{ marginTop: "1em" }}>
        <ButtonStyle
          text="VOLVER A SELECCIÓN"
           onClick={() => NextOptions1()}
          width="177px"
          height="5vh"
        />
         </div>
        <div style={{ marginTop: "1em", paddingBottom: "3em" }}>
        <ButtonStyle
          text="CONTINUAR"
           onClick={() => NextOptions2()}
          width="177px"
          height="5vh"
        />
         </div>
       
    </div>
  );
}
export default WVisualizar;
