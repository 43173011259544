import Swal from "sweetalert2";

function fatalError(api, reason) {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Encontramos un error y debemos reiniciar el registro",
  }).then(() => {
    if (api) {
      api.log(reason, "Fatal error");
    }
    window.location.reload();
  });
}

function commonError(reason, callback) {
  return Swal.fire({
    icon: "error",
    title: "Oops!",
    text: reason,
  }).then(() => {
    if (callback) callback();
    else window.location.reload();
  });
}

function successMessage(reason) {
  return Swal.fire({
    icon: "success",
    title: "",
    text: reason,
  });
}

function infoMessage(reason) {
  return Swal.fire({
    icon: "info",
    title: "Información",
    text: reason,
  });
}

function withInputAndCall(title, message, confirmText, call, validator) {
  return Swal.fire({
    title: title,
    text: message,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    cancelButtonText: "Cancelar",
    inputValidator: validator,
    showCancelButton: true,
    confirmButtonText: confirmText,
    showLoaderOnConfirm: true,
    preConfirm: (txt) => call(txt),
    allowOutsideClick: () => !Swal.isLoading(),
  });
}
export { fatalError, commonError, infoMessage, successMessage, withInputAndCall };