import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button } from "../../utils/Buttons/Buttons";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";
import MultipleChoice from "../MultipleChoice/MultipleChoice";
import { useHistory } from "react-router-dom";

function FinalForm2(props) {
  const query = useQuery();
  const id = query.get("filledFormId");
  console.log(id);
  const regId = query.get("registrationId");
  const api = new DummiesApi();
  var [data, setData] = useState();
  var [txtFields, setTxtFields] = useState({});
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [page, setPage] = useState(0);
  const [numPages, setNumPages] = useState(-1);
  const [currentTitle, setCurrentTitle] = useState("");
  const [form, setForm] = useState({});
  const [rows, setRows] = useState([]);

  let history = useHistory();

  async function nextPage() {
    if (!dataFilled()) {
      infoMessage("Por favor llena todos los datos");
      return;
    }
    console.log(txtFields);
    if (page === numPages - 1) {
      generateData();
    } else {
      setCurrentTitle(form.summaryPages[page + 1].title);
      setPage(page + 1);
    }
  }

  async function previousPage() {
    if (page === 0) {
    } else {
      setPage(page - 1);
    }
  }

  function dataFilled() {
    var accept = true;
    data.fields.map((v, i) => {
      let fieldId = v.id ? v.id : i;
      if (
        v.summaryPage === page &&
        v.type !== "label" &&
        v.type !== "labelSummary"
      ) {
        if (!txtFields[fieldId] || txtFields[fieldId] === "") {
          console.log("falta...");
          console.log("page: " + v.page);
          console.log(fieldId);
          console.log(txtFields[fieldId]);
          accept = false;
        }
      }
    });
    return accept;
  }

  useEffect(() => {
    console.log("loading data... for id: " + id);
    api.getFormByFilledFormId(id).then((res) => {
      if (res) {
        console.log(res);
        setCurrentTitle(res.summaryPages[0].title);
        setNumPages(res.summaryPages.length);
        let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
        console.log(newColor);
        setBgColor(newColor);
        setForm(res);
      }
    });
    loadData();
  }, []);

  async function loadData() {
    let res = await api.getFilledForm(id);

    setData(res);
    genRows(res);
  }

  function genRows(data2) {
    let rows2 = [];
    data2.fields.map((field, i) => {
      let fieldId = field.id ? field.id : i;
      function oc(ev) {}
      if (field.type !== "label" && field.type !== "labelSummary") {
        txtFields[fieldId] = "";
        oc = (ev) => {
          let txt = ev.target.value;
          console.log(txt);
          txtFields[fieldId] = txt;
        };
        txtFields[fieldId] = field.data;
      }
      var canEdit = field.userEditable;
      let type = field.type;
      if (
        type === "file" ||
        type === "multipleChoice" ||
        type === "singleChoice" ||
        type === "dropdown"
      )
        type = "text";
      let defaultValue = !field.data ? undefined : field.data;
      if (field.type === "multipleChoice" || type === "singleChoice") {
        defaultValue = defaultValue.replace("#", " ").replaceAll("#", ", ");
      }
      var f = (
        <GenericField
          onChange={(txt) => oc(txt)}
          disabled={!canEdit}
          defaultValue={defaultValue}
          key={i}
          title={field.title}
          fieldType={type}
          page={field.summaryPage}
          description={field.description}
        />
      );
      rows2.push(f);
    });
    setRows(rows2);
  }

  async function nextStep() {
    //terminar o firmar
    if (form.smartContract) {
      history.push("/electronicSignatureRedirect?filledFormId=" + id);
    } else {
      successMessage(
        "Tu proceso ha sido exitoso, en tu correo encontrarás información "
      ).then((e) => {
        api.sendFinalEmail(id);
        window.location.href = "https://anteia.co";
      });
    }
  }

  async function generateData() {
    var accept = true;
    for (const [key, value] of Object.entries(txtFields)) {
      if (!value || value === "") {
        console.log(key);
        console.log(value);
        accept = false;
      }
    }
    if (!accept) {
      infoMessage("Por favor llena todos los datos");
      return;
    }
    let formRes = await api.updateForm(txtFields, id);
    if (!formRes) {
      infoMessage("Error actualizando formulario!");
      return;
    }
    nextStep();
    // let res = await api.getAuthRedirect(id, regId);
    // if (!res) {
    //   infoMessage("Error creando autenticación!");
    //   return;
    // }
    // console.log(res);
    // successMessage("¡Listo! Ahora verificaremos tu identidad").then(
    //   (_) => (window.location.href = res.url)
    // );
  }

  if (!data) {
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <h1 style={{ color: "white" }}>Cargando...</h1>
      </div>
    );
  }
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        backgroundAttachment: "fixed",
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div className="col" style={{ paddingTop: "10%" }}>
        <h1 className="txt-label">{currentTitle}</h1>
        {rows.map((v, _) => {
          if (v.props.page === page) return v;
        })}
      </div>
      <div style={{ marginTop: "5%", marginBottom: "30%" }}>
        <Button text="Siguiente" onClick={() => nextPage()}></Button>
      </div>
    </div>
  );
}

export default FinalForm2;
