import React, {useState} from "react";
import "./TomaFront.scss";
import silueta from "./../../assets/silueta-01 3.png";
// import {useHistory} from 'react-router-dom';
import VideoMock from "../VideoMock/VideoMock";
import video from "./../../assets/video1.mp4";


function TomaFront(props) {
  // const history= useHistory()
  const [show, setShow]= useState(false)
  const GoToVideo=()=>{
  setShow(true)
  }
  return (
    <>
 {!show ? (
  <div className="toma1-style" onClick={()=>GoToVideo()}>
      <p className="txt-1">
        TOMA DE MI <br></br> VEHÍCULO
      </p>
      <p className="txt-2">
        Úbicate frente a tu <br></br> auto y logra que quede<br></br> dentro de
        la silueta
      </p>
      <img src={silueta} alt="img" />
      <p className="txt-3">
        Cuando estes listo<br></br>
        para grabar toca la<br></br> pantalla para tomar un<br></br> video de tu
        auto
      </p>
    </div>

 ) : (
  <VideoMock  video={video}  setShow={setShow} tomaroute={'/toma2'} send={props.send}/>
 )}


    
    </>
  );
}
export default TomaFront;
