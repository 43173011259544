import React from "react";
import "./ImprontaContract.scss";
import icon from "./../../assets/email.png";
import { Button1, Button } from "../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'
function ImprontaContract(props) {
    const history= useHistory()
    const SendInstructions =()=>{
        history.push('./contract'+ history.location.search)
    }
  return (
    <div className="impronta-advice">
      <p className='text-1'>
        Toma de <br></br>Contrato de mandato legal
      </p>
      
      <img src={icon} alt="icon" />
      <p className='text-3'>
       TEN A LA MANO <br></br> EL CONTRATO DE  <br></br>
        MANDATO LEGAL
      </p>
      <p className='text-4'>
        La cámara se encenderá y podrás enviar el contrato.
      </p>
     
      <div style={{marginTop: "2em" }}>
        <Button  text="TOMAR CONTRATO" onClick={()=>SendInstructions()} width="177px" height="19%"></Button>
      </div>
    </div>
  );
}
export default ImprontaContract;
