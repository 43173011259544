import React, { useState } from "react";
import "./BoxOption.scss";
import Radio from "@mui/material/Radio";
function BoxOption({option, price1, price2, text,selectedValue, setSelectedValue , value}) {
  

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div className="container">
      <div className="box">
        <div className="square">
          <fieldset>
            <legend></legend>
            <div className="text">
              <span>{option}</span>
            </div>
          </fieldset>
        </div>
        <div style={{ marginTop: "3%" }}>
          <Radio
            style={{ color: "#CBDACF" }}
            checked={selectedValue === value}
            onChange={handleChange}
            value={value}
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          />
        </div>
      </div>
      <div className="txt-iva">
        <p>PRIMA + IVA</p>
      </div>
      <div className="txt-price">
        <p>{price1}</p>
      </div>
      <div className="txt-text">
        <p>
  {text}
        </p>
      </div>
      <div className="txt-last">
        <p>Monto asegurado</p>
        <p>{price2}</p>
      </div>
    </div>
  );
}
export default BoxOption;
