import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from '../utils/UseQuery';
import { getColorsByExecFlow, sendOtp, verifyOtp } from './hyperFlowApi';
import { ButtonStyle } from '../utils/Buttons/Buttons';
import { ca } from 'date-fns/locale';
import OtpInput from 'react-otp-input-rc-17';
import { ClipLoader } from 'react-spinners';
import { hfErrorAlert, hfSuccessAlert, hfWarningAlert } from './HyperFlowAlerts';

interface Props {
  // Define the props for your component here
}

const HyperFlowOtp: React.FC<Props> = (props) => {
  // Implement your component logic here
  //get query params
  let history = useHistory();
  //query params
  const query = useQuery();
  const execFlowId = query.get("execFlowId") ?? "error";
  const code = query.get("code") ?? "error";
  const [otpVal, setOtpVal] = useState("")
  const [loading, setLoading] = useState(false);

  const [bgColor, setBgColor] = useState(["#000000", "#000000"]);
  const [darkMode, setDarkMode] = useState(true);
  const [showResend, setShowResend] = useState(false);
  const [phone, setPhone] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  //get colors
  async function getColors() {
    let resp = await getColorsByExecFlow(execFlowId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setDarkMode(resp.darkMode);
    setLogoUrl(resp.logoUrl)
  }
  const customStyle = {
    "--fontColorPrimary": darkMode ? "#ffffff" : "#000000",
  } as React.CSSProperties;

  async function sendOtpLocal() {
    setLoading(true)
    setShowResend(false)
    try {
      let resp = await sendOtp(execFlowId, code);
      console.log(resp);
      if (!resp) {
        await hfErrorAlert("Error enviando OTP")
        setShowResend(true)
      } else {
        setPhone(resp.phone)
      }
    } catch (e: any) {
      console.error(e)
      // Fire swal
      await hfErrorAlert("Error enviando OTP")
      setShowResend(true)
    }
    setLoading(false)
    setTimeout(() => {
      setShowResend(true)
    }, 10000);
  }

  function next() {
    try {
      setLoading(true);
      history.push("/hyperFlow/continueFlow?code=" + code);
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

  async function verifyOtpLocal() {
    setLoading(true)
    if (otpVal.length < 6) {
      // Fire swal
      await hfWarningAlert("Por favor ingrese el código de 6 dígitos completo")
      setLoading(false)
      return
    }
    let resp = await verifyOtp(execFlowId, code, otpVal);
    setLoading(false)
    switch (resp.status) {
      case "OK": {
        // await hfSuccessAlert("¡OTP verificado!")
        next();
        break;
      }
      case "INVALID": {
        await hfErrorAlert("Código inválido");
        setOtpVal("")
        break;
      }
      case "EXPIRED": {
        await hfErrorAlert("OTP expirado");
        setOtpVal("");
        break;
      }
      default: hfErrorAlert("Error verificando OTP"); break;
    }
  }

  useEffect(() => {
    // call send otp
    getColors();
    sendOtpLocal()


  }, []);
  if (loading) {
    return (
      <div className='hfMaster' style={customStyle}>
        <div style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}>
          <h1>Cargando...</h1>
          <ClipLoader loading={true} size={150} color={darkMode ? "#ffffff" : "#000000"} />
        </div>
      </div >
    )
  } else {
    return (
      // JSX code for your component's UI goes here
      <div className='hfMaster' style={customStyle}>
        <div style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          // width: "100%",
          overflow: "auto",
          paddingTop: "10%",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}>
          {/* Add your component's content here */}
          <div className="col">
            {
              logoUrl !== "" &&
              <div className='row'>
                <img src={logoUrl} alt="Logo" style={{ height: "100px" }} />
              </div>
            }

            <div className='row'>
              <h1 className="txt-label-title-hf">Para continuar con el proceso hemos enviado un código de confirmación a tu número de celular y correo electrónico</h1>
            </div>
            <div className='row' style={{ marginTop: "40px" }}>
              <h1 className="txt-label-title-hf" style={{ fontSize: "20px" }}>Ingresa el código de confirmación enviado a {phone}</h1>
            </div>
            <div className='' style={{ marginTop: "40px", alignItems: "center" }}>
              <OtpInput
                value={otpVal}
                onChange={setOtpVal}
                numInputs={6}
                inputStyle={"otpInput"}
                containerStyle={"otpContainer"}

              />

            </div>
            <div className='row' style={{ marginTop: "40px" }}>
              <ButtonStyle
                onClick={() => {
                  verifyOtpLocal();
                }}
                text="Verificar OTP"
                width="200px"
                height="60px"
              />
            </div>
            <div className='row' style={{ marginTop: "40px" }} >
              {(showResend && (
                <ButtonStyle
                  onClick={() => {
                    sendOtpLocal();
                  }}
                  text="Reenviar código"
                  width="200px"
                  height="60px"
                />
              ))}

            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default HyperFlowOtp;
