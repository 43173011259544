import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button } from "../../utils/Buttons/Buttons";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";

function FinalForm(props) {
  const query = useQuery();
  const id = query.get("filledFormId");
  console.log(id);
  const regId = query.get("registrationId");
  const api = new DummiesApi();
  var [data, setData] = useState();
  var [txtFields, setTxtFields] = useState({});
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  // var [bgColor, setBgColor] = useState(["#292840", "#566075"]);

  useEffect(() => {
    console.log("loading data... for id: " + id);
    api.getFormByFilledFormId(id).then((res) => {
      if (res) {
        let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
        console.log(newColor);
        setBgColor(newColor);
      }
    });
    loadData();
  }, []);

  async function loadData() {
    await api.addCoreIdData(regId, id);
    let res = await api.getFilledForm(id);
    setData(res);
  }

  async function generateData() {
    var accept = true;
    for (const [key, value] of Object.entries(txtFields)) {
      if (!value || value === "") {
        accept = false;
      }
    }
    if (!accept) {
      infoMessage("Por favor llena todos los datos");
      return;
    }
    let formRes = await api.updateForm(txtFields, id);
    if (!formRes) {
      infoMessage("Error actualizando formulario!");
      return;
    }
    let res = await api.getAuthRedirect(id, regId);
    if (!res) {
      infoMessage("Error actualizando creando autenticación!");
      return;
    }
    console.log(res);
    successMessage("¡Listo! Ahora verificaremos tu identidad").then(
      (_) => (window.location.href = res.url)
    );
  }

  if (!data) {
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <h1 style={{ color: "white" }}>Cargando...</h1>
      </div>
    );
  }
  let rows = [];
  data.fields.map((field, i) => {
    let fieldId = field.id ? field.id : i;
    txtFields[fieldId] = "";
    function oc(ev) {
      let txt = ev.target.value;
      console.log(txt);
      txtFields[fieldId] = txt;
    }
    txtFields[fieldId] = field.data;
    console.log(field.userEditable);
    var canEdit = field.userEditable;
    var f = (
      <GenericField
        onChange={(txt) => oc(txt)}
        disabled={!canEdit}
        defaultValue={!field.data ? undefined : field.data}
        key={i}
        title={field.title}
        fieldType={field.type}
      />
    );
    rows.push(f);
  });
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        backgroundAttachment: "fixed",
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div className="col" style={{ paddingTop: "10%" }}>
        <h1 className="txt-label">Revisa tus datos</h1>
        {rows}
      </div>
      <div style={{ marginTop: "5%", marginBottom: "30%" }}>
        <Button text="Siguiente" onClick={() => generateData()}></Button>
      </div>
    </div>
  );
}

export default FinalForm;
