import React,{useState} from "react";
import "./EnterData.scss";
import {TextFieldStyle} from "./../../utils/TextFieldStyle/TextFieldStyle";
import { Button } from "../../utils/Buttons/Buttons";

function EnterData(props) {
  const [placa, setPlaca] = useState("");
  const [number, setNumber] = useState("");

  const Next = () => {
    props.sendData( number, placa);
  
  };

  

  return (
    <div className="enterdata-style">
      <p>
        Ingresa los <br></br>siguientes <br></br> datos
      </p>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyle label="Placa" onChange={(e)=>{setPlaca(e.target.value)}} />
      </div>
      <div style={{ marginTop: "3em" }}>
        <TextFieldStyle label="Cédula" onChange={(e)=>{setNumber(e.target.value)}}  />
      </div>
      <div className="btn-enter">
        <Button
          text="SIGUIENTE"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default EnterData;
