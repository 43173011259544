import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3, ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../../Dummies/InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import { useHistory } from "react-router-dom";
import "./ins_new.scss";
import logoIns from "../../assets/logoins.png";

function InsRegisterIntro(props) {
  let history = useHistory();
  const api = new DummiesApi();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const formId = "621585769ffb931f0be5cfa3";

  async function next() {
    var filledFormId = await createForm();
    var coreIdResp = await api.getCoreIdLinkGeneric2(
      "https://magicforms.anteia.co/insCoreIdCallback",
      { filledFormId: filledFormId },
      formId
    );
    if (!coreIdResp) {
      console.log("Error, core id resp is null!");
      return;
    }
    console.log("url: " + coreIdResp.url);
    window.location = coreIdResp.url;
  }
  async function createForm() {
    console.log("sending form...");
    var resp = await api.sendForm({}, formId);
    if (!resp) {
      console.log("ERROR, resp is null");
      return;
    }
    console.log("filledFormId = " + resp.id);
    return resp.id;
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div>
        <div className="col" style={{ marginTop: "20vh" }}>
          <h1 className="txt-label txt-welcomeMessage">¡Genial!</h1>

          <h1
            className="txt-title"
            style={{ marginTop: "10vh" }}
          >
            En este momento vamos a crear tu identidad digital
          </h1>

          <div style={{ marginTop: "30%" }}>
            <ButtonStyle
              className="button-custom"
              text="Crear identidad digital"
              width={"60vw"}
              height={"50px"}
              onClick={() => next()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsRegisterIntro;
