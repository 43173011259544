import { createStyles, makeStyles, Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useEffect, useRef } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputLabel-outlined": {
        // Styles for the label when the input is not focused
        transform: "translate(14px, 14px)",
        fontSize: 14,
        color: "rgba(255, 255, 255, 0.55)",
        fontWeight: 500,
      },
      "& .MuiInputLabel-outlined.Mui-focused , & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)":
        {
          // Styles for the label when the input is focused
          transform: "translate(14px, -6px)",
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.55)",
          fontWeight: 500,
        },
      "& .MuiInputBase-root": {
        // Styles for the input element
        fontSize: 16,
        fontWeight: 500,
        color: "#fff",
        "& .MuiInputBase-input": {
          textAlign: "center", // center the text inside the input
        },
      },

      "& .MuiOutlinedInput-root": {
        // Styles for the outlined input container
        width: "246px",
        padding: "2px 5px 2px 5px",

        "& fieldset": {
          borderColor: "rgba(255, 255, 255, 0.50)",
          borderRadius: 10,
        },
        "&:hover fieldset": {
          borderColor: "rgba(255, 255, 255, 0.5)",
        },
        "&.Mui-focused fieldset": {
          borderColor: "rgba(255, 255, 255, 0.5)",
        },
        //styles for the content
      },
    },
    notEmpty: {
      "& .MuiInputLabel-outlined": {
        // Styles for the label when the input is not focused
        transform: "translate(90px, 14px)",
        fontSize: 14,
        color: "rgba(255, 255, 255, 0.55)",
        fontWeight: 500,
      },
      "& .MuiInputLabel-outlined.Mui-focused , & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)":
        {
          // Styles for the label when the input is focused
          transform: "translate(14px, -6px)",
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.55)",
          fontWeight: 500,
        },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& .MuiInputBase-root": {
        // Styles for the input element
        fontSize: 16,
        fontWeight: 500,
        color: "#fff",
        "& .MuiInputBase-input": {
          textAlign: "center", // center the text inside the input
        },
      },

      "& .MuiOutlinedInput-root": {
        // Styles for the outlined input container
        width: "246px",
        padding: "2px 5px 2px 5px",

        "& fieldset": {
          borderColor: "rgba(255, 255, 255, 0.50)",
          borderRadius: 10,
        },
        "&:hover fieldset": {
          borderColor: "rgba(255, 255, 255, 0.5)",
        },
        "&.Mui-focused fieldset": {
          borderColor: "rgba(255, 255, 255, 0.5)",
        },
        //styles for the content
      },
    },
  })
);

interface FloatingLabelInputProps {
  label: string;
  currentValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const FloatingLabelButton = (props: FloatingLabelInputProps) => {
  const classes = useStyles();

  const inputLabelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {}, []);
  return (
    <TextField
      label={props.label}
      variant="outlined"
      margin="dense"
      InputLabelProps={{
        ref: inputLabelRef,
        shrink: true,
      }}
      className={
        props.currentValue && props.currentValue.length > 0
          ? classes.notEmpty
          : classes.root
      }
      value={props.currentValue}
      onChange={props.onChange}
    />
  );
};
