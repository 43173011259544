import React from "react";
import "./Branding.scss";
import logo1 from "./../../assets/logoD.png";
import logo2 from "./../../assets/logoD2.png";
import iconD from "./../../assets/iconD.png";
import pallete from "./../../assets/pallete-color.png";
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";



function Branding() {
  return (
    <div className="branding">
      <div className="mini-nav">
        <p>Branding</p>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <div className="square-col">
              <p>App Icon</p>
              <div className="square-logo-color"></div>
              <div className='square-logo-color2'>
                <img src={iconD} alt="icon" />
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <div className="square-col2">
              <p>Logo</p>
              <div className="square-logo1">
                <img src={logo1} alt="logo" />
              </div>
              <div className="square-logo2">
                <img src={logo2} alt="logo" />
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <div className="square-col3">
              <div className="square-pallete">
                <img src={pallete} alt="logo" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default Branding;
