import React from "react";
import "./Typography.scss";
import Grid from "@mui/material/Grid";
function Typography() {
  const txtline1 = [
    {
      id: 1,
      name: "Title 1",
      fontWeight: "normal",
      name1: "Subtitle 1",
      name2: "Normal 1",
    },
    {
      id: 2,
      name: "Title 2",
      fontWeight: "600",
      name1: "Subtitle 2",
      name2: "Normal 2",
    },
    {
      id: 3,
      name: "Title 3",
      fontWeight: "bold",
      name1: "Subtitle 3",
      name2: "Normal 3",
    },
  ];
  const txtline2 = [
    {
      id: 1,
      name: "Caption 1",
      name1: "Menu 1",
      fontWeight: "normal",
    },
    {
      id: 2,
      name: "Caption 2",
      name1: "Menu 2",
      fontWeight: "600",
    },
  ];
  return (
    <div className="typography">
      <div className="mini-nav">
        <p>Typography System</p>
      </div>
      <div>
        <Grid container>
          {txtline1.map((i) => (
            <Grid item xs>
              <div>
                <p style={{ fontWeight: i.fontWeight, fontSize: "24px" }}>
                  {i.name}
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {txtline1.map((i) => (
            <Grid item xs>
              <div>
                <p style={{ fontWeight: i.fontWeight, fontSize: "18px" }}>
                  {i.name1}
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
        <p className='title-sub'>Subtitle Under 1</p>
        <Grid container>
          {txtline1.map((i) => (
            <Grid item xs>
              <div>
                <p style={{ fontWeight: i.fontWeight, fontSize: "14px" }}>
                  {i.name2}
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
        <p className='title-sub'>Normal Under 1</p>
        <Grid container>
          {txtline2.map((i) => (
            <Grid item xs={4}>
              <div>
                <p style={{ fontWeight: i.fontWeight, fontSize: "12px" }}>
                  {i.name}
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {txtline2.map((i) => (
            <Grid item xs={4}>
              <div>
                <p style={{ fontWeight: i.fontWeight, fontSize: "12px" }}>
                  {i.name1}
                </p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
export default Typography;
