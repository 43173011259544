import React from "react";
import logo from "./../../assets/logoins.png";
import "./SentSecurity.scss";
import { Button2, ButtonStyle } from "../../utils/Buttons/Buttons";
function SentSecurity() {
  const NextOptions =()=>{
    window.location = "https://anteia.co/";
  }
  return (
    <div className="container">
     
      <div className="texts">
        <p className="txt2">
          <span className="txt1">
            {" "}
            HEMOS ENVIADO<br></br>A TU CORREO ELECTRÓNICO
          </span>
          <br></br>
          TU SEGURO<br></br>DE VIAJES
        </p>
      </div>

      <div style={{ marginTop: "8em" }}>
        <ButtonStyle
          text="FINALIZAR"
          onClick={() => NextOptions()}
          
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default SentSecurity;
