enum ValidatorType {
  THRESHOLD_ABOVE = "THRESHOLD_ABOVE",
  THRESHOLD_BELOW = "THRESHOLD_BELOW",
  REGEX = "REGEX",
  EMAIL = "EMAIL",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
  BETWEEN = "BETWEEN",
  MULTI_SIZE = "MULTI_SIZE",
  MULTI_SUM = "MULTI_SUM",
  VALUE_NOT_EQUALS = "VALUE_NOT_EQUALS",
  TEXT_NUMBERS = "TEXT_NUMBERS",
  DATE_BEFORE = "DATE_BEFORE",
  DATE_AFTER = "DATE_AFTER",
  DATE_BETWEEN = "DATE_BETWEEN",
  DATE_BEFORE_PRESENT = "DATE_BEFORE_PRESENT",
  DATE_AFTER_PRESENT = "DATE_AFTER_PRESENT",
  DATE_IN_RANGE_BEFORE = "DATE_IN_RANGE_BEFORE",
  VALUE_EQUALS = "VALUE_EQUALS",
}

export default ValidatorType;
