import React from "react";
import "./ProgressBar.scss";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "blue" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFFF" : "blue",
  },
}));

function ProgressBar() {
  return (
    <div className="progress-bar">
      <div className="mini-nav">
        <p>Progress Bar</p>
      </div>
      <div className="progress-div">
        <BorderLinearProgress variant="determinate" value={50} />
      </div>
      <div className="progress-div">
        <BorderLinearProgress variant="determinate" value={20} />
      </div>
    </div>
  );
}
export default ProgressBar;
