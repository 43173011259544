import React,{useState} from "react";
import "./UIngresaCredito.scss";
import {TextFieldStyleSS, TextFieldStyleSSelect} from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import {useHistory} from 'react-router-dom'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { OutlinedInput } from "@mui/material";


function UIngresaCredito(props) {
 const history = useHistory();
const [data, setData]= useState("VIVIENDA");
  const Next= () => {
     props.getData();
   
    history.push('./VAdjunta'+ history.location.search)
  };
 
  const userChange = (name, value) => {
    var temp=props.data;
     temp[name]=value;
     props.setData(temp);
};

  return (
    <div className="enterdata-style">
      <p>
      Seleccione el tipo <br/> de crédito del que  <br/>  desea disponer
      </p>
      <br/>
      <br/>
      <TextFieldStyleSSelect data={
        <FormControl variant="outlined">
  <InputLabel id="demo-simple-select-label" style={{color: "white"}} shrink>Tipo de Crédito</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={data}
    style={{color: "white", borderColor: "white", width: "80vw"}}
   onChange={(e)=>{
    setData(e.target.value);
     userChange("tipocredito",e.target.value);}}
     
     input={<OutlinedInput notched label={"Tipo de Crédito"} />}
  >
    <MenuItem value={"VIVIENDA"}>VIVIENDA</MenuItem>
    <MenuItem value={"AUTOMOVIL"}>AUTOMOVIL</MenuItem>
  </Select>
</FormControl>

      }/> 
     
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Sector laboral" onChange={(e)=>{userChange("sector",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Monto a solicitar" onChange={(e)=>{userChange("monto",e.target.value)}} />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS label="Salario actual" onChange={(e)=>{userChange("salario",e.target.value)}} />
      </div>
      <div className="btn-enter">
        <ButtonStyle
          text="CONTINUAR"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      
      </div>
    </div>
  );
}
export default UIngresaCredito;
