import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DummiesApi from "../../api/dummies_api";
import { Button, Button3 } from "../../utils/Buttons/Buttons";
import { TextFieldStyle } from "../../utils/TextFieldStyle/TextFieldStyle";
import { TextField1 } from "../InputT/TextField1/TextField1";
import { infoMessage, successMessage } from "../../api/errorHandler";
import useQuery from "../../utils/UseQuery";
import "./InitialForm.scss";
import { GenericField } from "./GenericField";
import { useHistory } from "react-router-dom";

function InitialForm2(props) {
  const query = useQuery();
  const id = query.get("filledFormId");
  console.log(id);
  const regId = query.get("registrationId");
  console.log("regId: " + regId);
  const api = new DummiesApi();
  var [data, setData] = useState();
  var [txtFields, setTxtFields] = useState({});
  var [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  var [page, setPage] = useState(0);
  var [numPages, setNumPages] = useState(-1);
  var [currentTitle, setCurrentTitle] = useState("");
  const [rows, setRows] = useState([]);

  let history = useHistory();

  useEffect(() => {
    console.log("loading data... for id: " + id);
    api.getFormByFilledFormId(id).then((res) => {
      setData(res);
      console.log(res);
      setCurrentTitle(res.pages[0].title);
      setNumPages(res.pages.length);
      let newColor = [`#${res.firstColorHex}`, `#${res.secondColorHex}`];
      console.log(newColor);
      setBgColor(newColor);
      genRows(res);
      addCoreIdData();
    });
  }, []);

  async function addCoreIdData() {
    await api.addCoreIdData(regId, id);
  }

  async function nextPage() {
    if (!dataFilled()) {
      infoMessage("Por favor llena todos los datos");
      return;
    }
    if (page === numPages - 1) {
      generateData();
    } else {
      setCurrentTitle(data.pages[page + 1].title);
      setPage(page + 1);
    }
  }

  async function previousPage() {
    if (page === 0) {
    } else {
      setPage(page - 1);
    }
  }

  function dataFilled() {
    var accept = true;
    console.log(txtFields);
    data.fields.map((v, i) => {
      let fieldId = v.id ? v.id : i;
      if (
        v.page === page &&
        !v.autoLoadable &&
        v.type !== "label" &&
        v.type !== "labelSummary"
      ) {
        if (!txtFields[fieldId] || txtFields[fieldId] === "") {
          console.log("falta...");
          console.log("page: " + v.page)
          console.log(fieldId);
          console.log(txtFields[fieldId]);
          accept = false;
        }
      }
    });
    return accept;
  }

  function genRows(data2) {
    //After load
    let localRows = [];
    data2.fields.map((field, i) => {
      let fieldId = field.id ? field.id : i;
      if (!field.autoLoadable) {
        if (field.type !== "label" && field.type !== "labelSummary")
          txtFields[fieldId] = "";
        function oc(ev) {
          let txt = ev.target.value;
          console.log(txt);
          txtFields[fieldId] = txt;
        }
        //OC for File
        if (field.type === "file") {
          oc = (ev) => {
            let txt = ev.target.files[0].name;
            txtFields[fieldId] = txt;
          };
          //OC for multipleChoice and singleChoice
        } else if (
          field.type === "multipleChoice" ||
          field.type === "singleChoice"
        ) {
          oc = (choice, checked) => {
            //Handle Otro
            var orig = txtFields[fieldId];
            if (choice.includes(":otro:")) {
              let sels = txtFields[fieldId].split("#");
              // let sels = ["", ""]
              sels.forEach((s) => {
                if (s.includes(":otro:")) {
                  orig = orig.replace(`#${s}`, "");
                }
              });
              if (checked) {
                orig = orig + `#${choice}`;
              }
              txtFields[fieldId] = orig;
              console.log(txtFields[fieldId]);
            }
            //Else
            else {
              if (checked) txtFields[fieldId] = orig + "#" + choice;
              else txtFields[fieldId] = orig.replace("#" + choice, "");
              console.log(txtFields[fieldId]);
            }
          };
        }
        var f = (
          <GenericField
            onChange={oc}
            title={field.title}
            key={i}
            fieldType={field.type}
            page={field.page}
            description={field.description}
            options={field.choices}
            supportsOther={field.supportsOther}
          />
        );

        localRows.push(f);
      }
    });
    setRows(localRows);
  }

  async function generateData() {
    var accept = true;
    console.log(txtFields);
    for (const [key, value] of Object.entries(txtFields)) {
      if (!value || value === "") {
        console.log(key);
        console.log(value);
        accept = false;
      }
    }
    if (!accept) {
      infoMessage("Por favor llena todos los datos");
      return;
    }
    let formRes = await api.updateForm(txtFields, id);
    if (!formRes) {
      infoMessage("Error enviando formulario!");
      return;
    }
    console.log(formRes);
    history.push(`/finalForm2/?filledFormId=${id}&registrationId=${regId}`);
  }
  //Loading
  if (!data) {
    return (
      <div
        style={{
          background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <h1 style={{ color: "white" }}>Cargando...</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="col"
        style={{ paddingTop: "10%", marginLeft: "10vw", marginRight: "10vw" }}
      >
        <h1 className="txt-label">{currentTitle}</h1>
        {rows.map((v, _) => {
          if (v.props.page === page) return v;
        })}
      </div>
      <div style={{ marginTop: "5%", marginBottom: "30%" }}>
        <Button
          className="button-custom"
          text="Siguiente"
          onClick={() => nextPage()}
        />
      </div>
    </div>
  );
}

export default InitialForm2;
