import React from "react";
import "./SliderSecurity1.scss";
import BoxOption from "./BoxOption";
import more from "./../../assets/more.png";
import {useHistory} from 'react-router-dom'
function SliderSecurity1(props) {
  let history= useHistory();
  const Next=()=>{
history.push("slider2"+ history.location.search);
  }
  return (
    <>
      <div>
        <p className='txt-title'>Selecciona la opción de aseguramiento</p>
        <BoxOption
          option="OPCIÓN 01"
          price1="$ 37.06"
          text="Muerte Accidental del Asegurado Gastos Médicos y Adicionales Renta Diaria por Hospitalización Cancelación y acortamiento de viaje Retraso en el Viaje Pérdida de Equipaje Pérdida de Pasaporte Responsibilidad Civil x Lesión y/o muerte Honorarios Legales x Acc. Transporte Fianzas Legales"
          price2="$ 63.525"
          selectedValue={props.selectedValue} 
          setSelectedValue={props.setSelectedValue}
          value="a"
        />
        <BoxOption
          option="OPCIÓN 02"
          price1="$ 75.00"
          text="Muerte Accidental del Asegurado Gastos Médicos y Adicionales Renta Diaria por Hospitalización Cancelación y acortamiento de viaje Retraso en el Viaje Pérdida de Equipaje Pérdida de Pasaporte Responsibilidad Civil x Lesión y/o muerte Honorarios Legales x Acc. Transporte Fianzas Legales"
          price2="$ 132.275"
          selectedValue={props.selectedValue} 
          setSelectedValue={props.setSelectedValue}
          value="b"
        />

        <div className="btn-more" onClick={()=>Next()}>
          <p>
            ver más<br></br>
            opciones
          </p>
          <img src={more} alt="more" />
        </div>
      </div>
    </>
  );
}

export default SliderSecurity1;
