import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../magic_forms_2/store/hooks";
import useQuery from "../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import { ReactComponent as Contact } from "../assets/contact.svg";
import { ReactComponent as Phone } from "../assets/phone.svg";
import { ReactComponent as Place } from "../assets/place.svg";
import {
  getAsesorBrandConfig,
  getAsesorInfo,
  getAsesorPic,
  selectAsesor,
  selectAsesorPic,
  selectAsesorPicLoading,
  selectBrandConfig,
  selectLoadingAsesor,
  selectLoadingBrandConfig,
} from "./asesorSlice";
import { getFlujoAsesor } from "../hyperFlow/hyperFlowApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AsesorCard = () => {
  let asesor = useAppSelector(selectAsesor);
  let brandConfig = useAppSelector(selectBrandConfig);
  let loadingInfo = useAppSelector(selectLoadingAsesor);
  let loadingPic = useAppSelector(selectAsesorPicLoading);
  let loadingBrandConfig = useAppSelector(selectLoadingBrandConfig);
  let picBase64 = useAppSelector(selectAsesorPic);

  let dispatch = useAppDispatch();

  const query = useQuery();
  const idAsesor = query.get("idAsesor") ?? "error";

  const getTextColorForBackground = (bgColor: string) => {
    bgColor = bgColor.replace("#", "");

    // Convert the hex color to RGB
    let r = parseInt(bgColor.slice(0, 2), 16);
    let g = parseInt(bgColor.slice(2, 4), 16);
    let b = parseInt(bgColor.slice(4, 6), 16);

    // Calculate the luminance according to the relative luminance formula
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // If luminance is less than a threshold, return white; otherwise, return black
    return luminance < 128 ? "#FFFFFF" : "#000000";
  };

  const content = () => {
    if (loadingInfo === "pending" || loadingBrandConfig === "pending") {
      return <ClipLoader color="#fff" size={120} />;
    } else if (loadingInfo === "resolved") {
      return (
        <div
          style={{
            fontFamily: "Open Sans",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            background: `linear-gradient(180deg, ${brandConfig.brandColor} 55.35%, ${brandConfig.brandColor2} 104.8%)`,
          }}
        >
          <img
            alt="Logo de la empresa"
            src={brandConfig.brandLogoUrl}
            style={{
              maxWidth: "35%",
              maxHeight: "15%",
              width: "auto",
              height: "auto",
              margin: "10px 0 10px 0",
            }}
          />

          <div
            style={{ width: "40%", maxHeight: "266px", margin: "20px 0 0 0" }}
          >
            {loadingPic === "pending" ? (
              <ClipLoader
                color={getTextColorForBackground(brandConfig.brandColor)}
                size={40}
              />
            ) : (
              <img
                alt="Imagen del asesor"
                src={`data:image/png;base64, ${picBase64}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                  borderRadius: "30px",
                }}
              />
            )}
          </div>
          {asesor.asesorData.cargo && (
            <p
              style={{
                fontSize: "12px",
                fontWeight: 800,
                textAlign: "center",
                color: getTextColorForBackground(brandConfig.brandColor),
                textTransform: "uppercase",
                margin: 0,
              }}
            >
              {asesor.asesorData.cargo}
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {asesor.name && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "21px 0 21px 0",
                }}
              >
                <Contact
                  fill={getTextColorForBackground(brandConfig.brandColor)}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    margin: "0 0 0 21px",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: getTextColorForBackground(brandConfig.brandColor),
                      textTransform: "uppercase",
                      margin: 0,
                    }}
                  >
                    {asesor.name}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: getTextColorForBackground(brandConfig.brandColor),
                      textTransform: "uppercase",
                      margin: 0,
                    }}
                  >
                    {asesor.identification}
                  </p>
                </div>
              </div>
            )}
            {asesor.phoneNumber && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 0 21px 0",
                }}
              >
                <Phone
                  fill={getTextColorForBackground(brandConfig.brandColor)}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    margin: "0 0 0 21px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: getTextColorForBackground(brandConfig.brandColor),
                      textTransform: "uppercase",
                      margin: 0,
                    }}
                  >
                    <a
                      style={{ color: "inherit", textDecoration: "none" }}
                      href={`tel:+${asesor.phoneNumber}`}
                    >
                      {asesor.phoneNumber}
                    </a>
                  </p>{" "}
                </div>
              </div>
            )}
            {asesor.asesorData.direccion && asesor.asesorData.oficina && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 0 21px 0",
                }}
              >
                <Place
                  style={{ margin: "0 0 0 3px " }}
                  fill={getTextColorForBackground(brandConfig.brandColor)}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    margin: "0 0 0 24px",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: getTextColorForBackground(brandConfig.brandColor),
                      textTransform: "uppercase",
                      margin: 0,
                    }}
                  >
                    {asesor.asesorData.direccion}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                      color: getTextColorForBackground(brandConfig.brandColor),
                      textTransform: "uppercase",
                      margin: 0,
                    }}
                  >
                    Oficina {asesor.asesorData.oficina}
                  </p>
                </div>
              </div>
            )}
          </div>
          {asesor.asesorData.codAsesor && (
            <div
              onClick={() => {
                navigator.clipboard.writeText(asesor.asesorData.codAsesor);
                toast.success("Código copiado al portapapeles", {
                  position: "bottom-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }}
              style={{
                width: "211px",
                height: "28px",
                padding: "7px 0 7px 0",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
                color: getTextColorForBackground(brandConfig.brandColor),
                backgroundColor: "transparent",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                outline: "none",
                position: "relative",
                margin: "10px 0 20px 0",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: "-30px",
                  right: "0",
                  textTransform: "uppercase",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: getTextColorForBackground(brandConfig.brandColor),
                }}
              >
                Código referencia
              </div>
              <p
                style={{
                  margin: "5px 0 0 0",
                  textAlign: "center",
                  position: "relative",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: getTextColorForBackground(brandConfig.brandColor),
                }}
              >
                {asesor.asesorData.codAsesor}
              </p>
            </div>
          )}
          <button
            onClick={async (e) => {
              e.preventDefault();
              await getFlujoAsesor(idAsesor);
            }}
            style={{
              border: "none",
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
              borderRadius: "30px",
              height: "60px",
              width: "50%",
              color: "#fff",
              fontSize: "20px",
              textTransform: "uppercase",
              fontWeight: 500,
              fontFamily: "Open Sans",
              margin: "10px 0 0 0",
              cursor: "pointer",
            }}
          >
            Vincúlate
          </button>
        </div>
      );
    } else if (
      (loadingInfo === "idle" && idAsesor === "error") ||
      loadingInfo === "rejected"
    ) {
      return (
        <div
          style={{
            background: `linear-gradient(180deg, ${brandConfig.brandColor} 55.35%, ${brandConfig.brandColor2} 104.8%)`,
          }}
        ></div>
      );
    } else {
      return (
        <div
          style={{
            background: `linear-gradient(180deg, ${brandConfig.brandColor} 55.35%, ${brandConfig.brandColor2} 104.8%)`,
          }}
        ></div>
      );
    }
  };

  useEffect(() => {
    if (loadingInfo === "idle" && idAsesor !== "error") {
      dispatch(getAsesorInfo(idAsesor));
    }

    if (loadingPic === "idle" && idAsesor !== "error") {
      dispatch(getAsesorPic(idAsesor));
    }

    if (loadingBrandConfig === "idle" && idAsesor !== "error") {
      dispatch(getAsesorBrandConfig(idAsesor));
    }
    return () => {};
  }, [idAsesor, dispatch, loadingInfo, loadingBrandConfig]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {content()}
      <ToastContainer />
    </div>
  );
};
