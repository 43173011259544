import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./InitialForm.scss";
import { TextFieldStyleDummies } from "../../utils/TextFieldStyle/TextFieldStyle";
import NumberFormat from "react-number-format";
import Dropdown from "react-dropdown";
import {
  Autocomplete,
  Checkbox,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  ThemeProvider,
} from "@mui/material";
import { FormGroup, FormControlLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DatePicker from "react-datepicker";
import { format, getMonth, getYear, parse, setYear, toDate } from "date-fns";
import imgFlecha from "../../assets/flechaArriba.png";
import { ButtonStyleFileNoHeight } from "../../utils/Buttons/Buttons";
import { borderColor } from "@mui/system";
import { ClassificationTypeNames } from "typescript";
const ExampleCustomInput = forwardRef(({ value2, onClick2 }, ref) => (
  <button className="example-custom-input" onClick={onClick2} ref={ref}>
    {value2}
  </button>
  // <TextFieldStyleDummies onClick={onClick2} value={value2} innerRef={ref} />
));
const useStyles = makeStyles({
  inputRoot: {
    whiteSpace: "normal !important",
    color: "white",
    borderColor: "white",
    outlineColor: "white",
  },
  input: {
    whiteSpace: "normal !important",
    color: "white",
    borderColor: "white",
    outlineColor: "white",
  },

  select: {
    "MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
      {
        whiteSpace: "normal !important",
      },
    whiteSpace: "normal !important",
    "&:before": {
      whiteSpace: "normal !important",
      borderColor: "white",
      color: "white !important",
    },
    "&:after": {
      whiteSpace: "normal !important",
      borderColor: "white",
      color: "white !important",
    },
    "&:not(.Mui-disabled):hover::before": {
      whiteSpace: "normal !important",
      borderColor: "white !important",
      color: "white",
    },
    "MuiOutlinedInput-root": {
      whiteSpace: "normal !important",
      color: "white",
      borderColor: "white !important",
    },
  },
  icon: {
    whiteSpace: "normal !important",
    fill: "white",
    outlineColor: "white",
    borderColor: "white !important",
  },
  root: {
    whiteSpace: "normal !important",
    color: "white",
    outlineColor: "white",
    borderColor: "white !important",
    fieldset: {
      borderColor: "white !important",
    },
  },
  inputStuff: {
    whiteSpace: "normal !important",
  },
  text: {
    whiteSpace: "normal !important",
    color: "white ",
    outlineColor: "white",
    borderColor: "white !important",
    fieldset: {
      whiteSpace: "normal !important",
      borderColor: "white !important",
    },
  },
});

const useStyles2 = makeStyles({
  inputRoot: {
    whiteSpace: "normal !important",
    color: "black",
    borderColor: "black",
    outlineColor: "black",
  },
  input: {
    whiteSpace: "normal !important",
    color: "black",
    borderColor: "black",
    outlineColor: "black",
  },

  select: {
    "MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
      {
        whiteSpace: "normal !important",
      },
    whiteSpace: "normal !important",
    "&:before": {
      whiteSpace: "normal !important",
      borderColor: "black",
      color: "black !important",
    },
    "&:after": {
      whiteSpace: "normal !important",
      borderColor: "black",
      color: "black !important",
    },
    "&:not(.Mui-disabled):hover::before": {
      whiteSpace: "normal !important",
      borderColor: "black !important",
      color: "black",
    },
    "MuiOutlinedInput-root": {
      whiteSpace: "normal !important",
      color: "black",
      borderColor: "black !important",
    },
  },
  icon: {
    whiteSpace: "normal !important",
    fill: "black",
    outlineColor: "black",
    borderColor: "black !important",
  },
  root: {
    whiteSpace: "normal !important",
    color: "black",
    outlineColor: "black",
    borderColor: "black !important",
    fieldset: {
      borderColor: "black !important",
    },
  },
  inputStuff: {
    whiteSpace: "normal !important",
  },
  text: {
    whiteSpace: "normal !important",
    color: "black ",
    outlineColor: "black",
    borderColor: "black !important",
    fieldset: {
      whiteSpace: "normal !important",
      borderColor: "black !important",
    },
  },
});

const years = Array.from(new Array(200), (x, i) => i + 1900);
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

/**
 * types: text, money, multiple
 * @param {*} param0
 * @param {Array} options options
 * @param {FieldValidator} validator
 * @returns
 */
export const GenericField2 = ({
  label,
  onChange,
  value,
  type,
  disabled = false,
  defaultValue,
  InputProps,
  fieldType,
  title,
  options,
  description,
  supportsOther,
  fileTypes,
  validator,
  darkMode = true,
  deleteFileFunction,
}) => {
  console.log("DarkMode: " + darkMode);
  const customStyle = {
    "--textColor": darkMode ? "#000000" : "#FFFFFF",
  };
  let [otherVal, setOtherVal] = useState("");
  let [multiVal, setMultiVal] = useState(value);
  const [dateE, setDate] = useState(null);
  const classes = useStyles();
  const classesLight = useStyles2();
  //dd special
  const [otherDdAct, setOtherDdAct] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  //file ref
  const fileRef = useRef(null);
  useEffect(() => {
    if (fieldType === "date" && value) {
      try {
        let nDate = parse(value, "dd/MM/yyyy", new Date());
        console.log("pasing date success for value: " + value);
        console.log(nDate);
        if (isNaN(nDate.getTime())) {
          throw "Error Date";
        }
        setDate(nDate);
      } catch (e) {
        console.log("error on date parsing for value: " + value);
      }
    }
  }, []);

  // Money filed type
  if (fieldType === "money") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={label}
          value={value}
          type={type}
          defaultValue={defaultValue}
          InputProps={{
            inputComponent: MoneyNumberFormat,
          }}
          darkMode={darkMode}
        />
      </div>
    );
  }
  // Money filed type
  if (fieldType === "moneyCr") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={label}
          value={value}
          type={type}
          defaultValue={defaultValue}
          InputProps={{
            inputComponent: MoneyNumberFormatCostaRica,
          }}
          darkMode={darkMode}
        />
      </div>
    );
  }
  if (fieldType === "moneyNi") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={label}
          value={value}
          type={type}
          defaultValue={defaultValue}
          InputProps={{
            inputComponent: MoneyNumberFormatNicaragua,
          }}
          darkMode={darkMode}
        />
      </div>
    );
  }
  // Multiple choice field
  else if (fieldType === "multipleChoice") {
    let checks = [];
    options.map((val, i) => {
      let c = (
        <FormControlLabel
          key={val}
          className="form-style"
          control={
            <Checkbox
              className="checkbox-style"
              onChange={(e) => {
                onChange(val, e.target.checked, i);
              }}
            />
          }
          label={val}
        />
      );
      checks.push(c);
    });
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <FormGroup>{checks}</FormGroup>
      </div>
    );
  }
  // Single choice field
  else if (fieldType === "singleChoice") {
    let a = "";
    let checks = [];
    let values = [];
    options.map((val, i) => {
      values.push(val);
      let c = (
        <FormControlLabel
          key={i}
          className="form-style"
          style={{ textAlign: "start", marginBottom: "10px" }}
          value={val}
          control={<Radio className="checkbox-style" />}
          label={val}
        />
      );
      checks.push(c);
    });
    let c = <div></div>;
    if (supportsOther) {
      values.push("otro");
      c = (
        <div key={"otro"} style={{ marginTop: "3vh" }}>
          <TextFieldStyleDummies
            onChange={(e) => {
              let val = e.target.value;
              setOtherVal(val);
              setMultiVal("");
              onChange(`:otro:${val}`, e.target.value !== "");
              // values.forEach((val2) => {
              //   onChange(val, false);
              // });
            }}
            disabled={disabled}
            label={"Otro"}
            value={otherVal}
            darkMode={darkMode}
          />
        </div>
      );
    }
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <FormGroup>
          <RadioGroup
            onChange={(e) => {
              let selected = e.target.value;
              setMultiVal(selected);
              onChange(selected);
              // values.forEach((val) => {
              //   onChange(val, selected === val);
              // });
              if (supportsOther) {
                // onChange(":otro:", false);
                setOtherVal("");
                // c.value = "";
              }
            }}
            value={multiVal}
          >
            {checks}
          </RadioGroup>
        </FormGroup>
        {c}
      </div>
    );
  }
  // Dropdown field
  else if (fieldType === "dropdown") {
    let a = "";
    let items = [];
    options.map((val, i) => {
      let c = (
        <MenuItem
          key={i}
          value={val}
          classes={{ root: classes.inputStuff }}
          style={{ whiteSpace: "normal !important" }}
        >
          {val}
        </MenuItem>
      );
      items.push(c);
    });
    if (supportsOther) {
      items.push(
        <MenuItem key={"otherr"} value={"Otro"}>
          {"Otro"}
        </MenuItem>
      );
    }
    return (
      <div className="row">
        <p
          className="txt-label"
          style={{
            borderColor: "white !important",
            whiteSpace: "normal !important",
          }}
        >
          {title}
        </p>
        <FormGroup
          sx={{
            whiteSpace: "normal !important",
            color: darkMode ? "white" : "black",
            borderColor: darkMode ? "white" : "black",
            outlineColor: darkMode ? "white" : "black",
            accentColor: darkMode ? "white" : "black",
          }}
          classes={{ root: darkMode ? classes.text : classesLight.text }}
        >
          <Select
            style={{ whiteSpace: "normal !important" }}
            // className={classes.select}
            // className="muiinputstuff"
            sx={{
              whiteSpace: "normal !important",
              color: darkMode ? "white" : "black",
              borderColor: darkMode ? "white" : "black",
              outlineColor: darkMode ? "white" : "black",
              accentColor: darkMode ? "white" : "black",
            }}
            classes={{
              // darkMode?classes:classesLight
              select: darkMode ? classes.select : classesLight.select,
              root: darkMode ? classes.text : classesLight.text,
              icon: darkMode ? classes.icon : classesLight.icon,
              border: darkMode ? classes.text : classesLight.text,
              fieldset: darkMode ? classes.text : classesLight.text,
            }}
            value={multiVal}
            inputProps={{
              classes: {
                icon: darkMode ? classes.icon : classesLight.icon,
                root: darkMode ? classes.root : classesLight.root,
                text: darkMode ? classes.text : classesLight.text,
              },
            }}
            onChange={(e) => {
              setMultiVal(e.target.value);
              onChange(e.target.value);
              if (e.target.value === "Otro") {
                setOtherDdAct(true);
              } else {
                setOtherDdAct(false);
              }
            }}
            disabled={disabled}
          >
            {items}
          </Select>
        </FormGroup>
        {/* Other field */}
        {otherDdAct && (
          <div style={{ marginBottom: "30px" }}>
            <p className="txt-label">{"¿Cual?"}</p>
            <TextFieldStyleDummies
              onChange={(ev) => {
                setOtherVal(ev.target.value);
                onChange(ev.target.value);
              }}
              disabled={disabled}
              label={label}
              value={otherVal}
              type={type}
              defaultValue={defaultValue}
              InputProps={InputProps}
              darkMode={darkMode}
            />
          </div>
        )}
      </div>
    );
  }
  //Dropdown Dynamic
  else if (
    fieldType === "dropdown_dynamic" ||
    fieldType === "dropdown_from_state"
  ) {
    let a = "";
    let itemsString = [];
    options.map((val, i) => {
      itemsString.push({ label: val });
    });

    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label" style={{ borderColor: "white !important" }}>
          {title}
        </p>
        <FormGroup
          sx={{
            color: darkMode ? "white" : "black",
            borderColor: darkMode ? "white" : "black",
            outlineColor: darkMode ? "white" : "black",
            accentColor: darkMode ? "white" : "black",
          }}
          classes={{ root: !darkMode ? classes.text : classesLight.text }}
        >
          <Autocomplete
            sx={{ textAlign: "center", color: darkMode ? "white" : "black" }}
            classes={darkMode ? classes : classesLight}
            value={multiVal}
            disabled={disabled}
            onChange={(e, nv) => {
              if (!nv) return;
              // console.log(e);
              console.log(nv);
              setMultiVal(nv);
              onChange(nv.label);
              // onChange()
              // setMultiVal(e.target.value);
              // onChange(e.target.value);
              if (e.target.value === "Otro") {
                setOtherDdAct(true);
              } else {
                setOtherDdAct(false);
              }
            }}
            disablePortal
            options={itemsString}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                sx={{
                  color: darkMode ? "white !important" : "black !important",
                }}
                color={darkMode ? "white" : "black"}
              />
            )}
          />
        </FormGroup>
        {/* Other field */}
        {otherDdAct && (
          <div style={{ marginBottom: "30px" }}>
            <p className="txt-label">{"¿Cual?"}</p>
            <TextFieldStyleDummies
              onChange={(ev) => {
                setOtherVal(ev.target.value);
                onChange(ev.target.value);
              }}
              disabled={disabled}
              label={label}
              value={otherVal}
              type={type}
              defaultValue={defaultValue}
              InputProps={InputProps}
              darkMode={darkMode}
            />
          </div>
        )}
      </div>
    );
  }
  // File field
  else if (fieldType === "file") {
    // if(fileTypes) {

    // }
    return (
      <div style={{ padding: "1px", display: "flex", flexDirection: "column", alignItems:"center", marginTop:"20px" }}>
        {/* <p className="txt-label">{title}</p> */}
        {/* <input type="file" onChange={onChange} /> */}
        <input
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={onChange}
        />
        <ButtonStyleFileNoHeight
          onClick={() => {
            fileRef.current.click();
          }}
          text={title}
          width="177px"
          height="40px"
        />
        <p className="txt-label">{value}</p>
        {value && value !== "" && (
          <ButtonStyleFileNoHeight 
          onClick={() => {
            console.log("delete file")
            deleteFileFunction();
          }}  
          text={"Eliminar"}
          width="150px"
          height="40px"/>
        )}
      </div>
    );
  }
  // Number field
  else if (fieldType === "number") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={label}
          value={value}
          type={"number"}
          defaultValue={defaultValue}
          darkMode={darkMode}
        />
      </div>
    );
  }
  // Date field
  else if (fieldType === "date") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
          // format="dd/MM/yyyy"
          onChange={(e) => {
            console.log("changed something!");
            setDate(e);
            if (!e) return;
            console.log(e);
            let date2 = e;
            let month = date2.getMonth() + 1;
            let year = date2.getFullYear();
            let day = date2.getDate();
            let dateString = `${day}/${month}/${year}`;
            onChange(dateString);
          }}
          dateFormat="dd/MM/yyyy"
          selected={dateE}
          style={{ width: "5em", color: "black" }}
          wrapperClassName="datePicker"
          disabled={disabled}
        />
      </div>
    );
  }
  // Label Field
  else if (fieldType === "label" || fieldType === "labelSummary") {
    return (
      <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title}
        </p>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  } else if (fieldType === "dynamic_label") {
    let line = title.replace("{{}}", value);
    // split line into paragraphs by \n and add them to an array
    let paragraphs = line.split("\n");

    return (
      <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        {/* <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        > */}
        {/* map paragraphs into texts */}
        {paragraphs.map((paragraph, i) => (
          <p
            key={i}
            className="txt-label"
            style={{ marginLeft: "10vw", marginRight: "10vw" }}
          >
            {paragraph}
          </p>
        ))}
        {/* {title.replace("{{}}", value)} */}
        {/* </p> */}
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  } else if (fieldType === "read_more") {
    return (
      <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title}
        </p>
        {showDesc && (
          <div
            style={{
              height: "30vh",
              overflow: "auto",
            }}
          >
            <p
              className="txt-label"
              style={{
                marginLeft: "2vw",
                marginRight: "2vw",
                fontSize: "16px",
                textAlign: "start",
              }}
            >
              {description}
            </p>
          </div>
        )}
        {!showDesc && (
          <p
            className="txt-label"
            style={{
              marginLeft: "10vw",
              marginRight: "10vw",
              fontSize: "16px",
            }}
          >
            {description.substring(0, 40) + "..."}
          </p>
        )}
        {!showDesc && (
          <p
            className="txt-label"
            onClick={() => {
              setShowDesc(true);
            }}
          >
            {"Leer más    "}{" "}
            <img
              src={imgFlecha}
              className="img-flecha-inv"
              style={{
                height: "12px",
                width: "16px",
                transform: [{ rotate: "180deg" }],
              }}
            ></img>
          </p>
        )}
        {showDesc && (
          <p
            className="txt-label"
            style={{ fontSize: "16px" }}
            onClick={() => {
              setShowDesc(false);
            }}
          >
            {"Leer menos   "}
            <img
              src={imgFlecha}
              style={{ height: "12px", width: "16px" }}
            ></img>
          </p>
        )}
      </div>
    );
  }

  // Default field
  else {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={label}
          value={value}
          type={type}
          defaultValue={defaultValue}
          InputProps={InputProps}
          darkMode={darkMode}
        />
      </div>
    );
  }
};

function MoneyNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$"}
      // isNumericString
    />
  );
}

function MoneyNumberFormatCostaRica(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"₡"}
      // isNumericString
    />
  );
}

function MoneyNumberFormatNicaragua(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"C$"}
      // isNumericString
    />
  );
}
