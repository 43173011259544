import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { Button2, ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import logoIns from "../../assets/logoins.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DatePicker from "react-date-picker";
import {
  TextFieldStyle,
  TextFieldStyle2,
  TextFieldStyle3,
  TextFieldStyleDummies,
  TextFieldStyleSS,
  TextFieldStyleSSelect,
} from "./../../utils/TextFieldStyle/TextFieldStyle";

import "./ins_new.scss";
import { TextField } from "@material-ui/core";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";


function InsForm2(props) {
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const [selectedValue, setSelectedValue] = useState("a");
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [destination, setDestination] = useState("");
  const [plan, setPlan] = useState("basico");

  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    var value = "basico";
    if (event.target.value == "b") {
      value = "completo";
    }
    setPlan(value);
    // userChange("type_plan", value);
  };

  function formatDate(date) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    return `${day}/${month}/${year}`;
  }

  async function sendData() {
    let data = {
      destination: destination,
      initialDate: formatDate(initialDate),
      endDate: formatDate(endDate),
      plan: plan,
    };
    console.log(data);
    await api.updateForm(data, filledFormId);
    var currentForm = await api.getFilledForm(filledFormId);
    console.log(currentForm);
    history.push("/insForm3" + history.location.search);
  }

  const NextOptions = () => {
    history.push("./slider1" + history.location.search);
  };
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div className="" style={{ marginTop: "10vh" }}>
        <div>
          <TextFieldStyleSS
            label="Destino de tu viaje"
            onChange={(e) => {
              setDestination(e.target.value);
              // userChange("destino", e.target.value);
            }}
          />
        </div>

        <div style={{ marginTop: "2em" }}>
          <p className="txt-label">¿ Cuándo iniciará tu viaje ?</p>
          <DatePicker
            format="y-MM-dd"
            onChange={(e) => {
              console.log(e);
              setInitialDate(e);
              // userChange("start_date", e);
            }}
            value={initialDate}
            style={{ width: "4em", color: "white" }}
          />
        </div>
        <div style={{ marginTop: "2em" }}>
          <p className="txt-label">¿ Cuándo finalizará tu viaje ?</p>
          <DatePicker
            format="y-MM-dd"
            onChange={(e) => {
              setEndDate(e);
              console.log(e);
              // userChange("end_date", e);
            }}
            value={endDate}
          />
        </div>
        {/* <div style={{ marginTop: "3em" }}>
          <TextFieldStyle3
            label="¿ Cuándo finalizará tu viaje ?"
            onChange={(e) => {
              console.log(e.target.value);
            }}
          />
        </div> */}
      </div>
      <p style={{ marginTop: "5vh" }} className="plan txt-label">
        Plan de aseguramiento
      </p>
      <div className="plan-square">
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={7}>
              <p style={{ marginRight: "10%", color: "white" }}>Plan Básico</p>
            </Grid>
            <Grid
              item
              xs={5}
              style={{ textAlignLast: "left", marginTop: "3%" }}
            >
              {" "}
              <Radio
                style={{ color: "#CBDACF" }}
                checked={selectedValue === "a"}
                onChange={handleChange}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 30,
                  },
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <p style={{ marginRight: "10%", color: "white" }}>
                Plan Completo
              </p>
            </Grid>
            <Grid
              item
              xs={5}
              style={{ textAlignLast: "left", marginTop: "3%" }}
            >
              {" "}
              <Radio
                style={{ color: "#CBDACF" }}
                checked={selectedValue === "b"}
                onChange={handleChange}
                value="b"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 30,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div style={{ margin: "2.4em" }}>
        <ButtonStyle
          text="SIGUIENTE"
          onClick={() => sendData()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default InsForm2;
