import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../magic_forms_2/store/hooks";
import useQuery from "../utils/UseQuery";
import {
  changeCreateAsesorRequest,
  createAsesor,
  selectAsesorCreateRequest,
  selectLoadingCreateAsesor,
  selectLoadingSendEmail,
  sendEmail,
} from "./asesorSlice";
import { FloatingLabelButton } from "./components/FloatingLabelButton";
import { ReactComponent as Upload } from "../assets/Upload.svg";
import { ReactComponent as QRCode } from "../assets/qrCode.svg";
import { ReactComponent as Camera } from "../assets/camera.svg";
import ClipLoader from "react-spinners/ClipLoader";

export const AsesorCreation = () => {
  let asesorCreateRequest = useAppSelector(selectAsesorCreateRequest);

  //get registrationId and code from query params
  const query = useQuery();
  const code = query.get("code") ?? "error";
  const registrationId = query.get("registrationId") ?? "error";
  let loadingCreateAsesor = useAppSelector(selectLoadingCreateAsesor);
  let loadingSendEmail = useAppSelector(selectLoadingSendEmail);

  const [page, setPage] = useState(0);
  const [fileError, setFileError] = useState("");

  let dispatch = useAppDispatch();

  function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  useEffect(() => {
    if (code !== "error" && registrationId !== "error") {
      dispatch(
        changeCreateAsesorRequest({
          ...asesorCreateRequest,
          code: code,
          registrationId: registrationId,
        })
      );
    }
    if (loadingCreateAsesor === "resolved" && loadingSendEmail === "idle") {
      dispatch(sendEmail());
    }
    if (loadingSendEmail === "resolved") {
      setPage(2);
    }

    return () => {};
  }, [loadingCreateAsesor, loadingSendEmail]);

  const getPageInfo = () => {
    if (loadingCreateAsesor === "pending" || loadingSendEmail === "pending") {
      return <ClipLoader color="#fff" size={120} />;
    } else {
      if (page === 0) {
        return (
          <>
            <p
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontWeight: 800,
                color: "#fff",
                width: "60%",
                margin: "60px 0 50px 0",
              }}
            >
              Antes de terminar con tu registro ingresa la siguiente información
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 0 35px 0",
              }}
            >
              <FloatingLabelButton
                label="CARGO"
                currentValue={asesorCreateRequest.cargo}
                onChange={(e) => {
                  dispatch(
                    changeCreateAsesorRequest({
                      ...asesorCreateRequest,
                      cargo: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 0 35px 0",
              }}
            >
              <FloatingLabelButton
                label="DIRECCIÓN DE TRABAJO"
                currentValue={asesorCreateRequest.direccion}
                onChange={(e) => {
                  dispatch(
                    changeCreateAsesorRequest({
                      ...asesorCreateRequest,
                      direccion: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 0 35px 0",
              }}
            >
              <FloatingLabelButton
                label="OFICINA"
                currentValue={asesorCreateRequest.oficina}
                onChange={(e) => {
                  dispatch(
                    changeCreateAsesorRequest({
                      ...asesorCreateRequest,
                      oficina: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 0 35px 0",
              }}
            >
              <FloatingLabelButton
                label="CÓDIGO ASESOR"
                currentValue={asesorCreateRequest.codAsesor}
                onChange={(e) => {
                  dispatch(
                    changeCreateAsesorRequest({
                      ...asesorCreateRequest,
                      codAsesor: e.target.value,
                    })
                  );
                }}
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPage(1);
              }}
              style={{
                border: "none",
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
                borderRadius: "30px",
                minHeight: "60px",
                width: "50%",
                color: "#fff",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: 500,
                fontFamily: "Open Sans",
                margin: "10px 0 60px 0",
                cursor: "pointer",
                maxWidth: "300px",
              }}
            >
              Siguiente
            </button>
            <p
              style={{
                width: "60%",
                textAlign: "start",
                fontSize: "10px",
                fontWeight: 600,
                color: "rgba(255,255,255,0.35)",
                margin: "30px 0",
              }}
            >
              Esta información se utiliza únicamente para validar tu identidad y
              es guardada de forma segura por anteia.
            </p>
          </>
        );
      } else if (page === 1) {
        return (
          <>
            <p
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontWeight: 800,
                color: "#fff",
                width: "60%",
                margin: "80px 0 70px 0",
              }}
            >
              Para finalizar debemos cargar tu imagen de presentación
            </p>
            {asesorCreateRequest.photoBase64.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "0 0 35px 0",
                }}
              >
                <img
                  alt="foto subida"
                  style={{
                    width: "70%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "10px",
                    margin: "0 0 20px 0",
                  }}
                  src={`data:image/png;base64,${asesorCreateRequest.photoBase64}`}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCreateAsesorRequest({
                        ...asesorCreateRequest,
                        photoBase64: "",
                      })
                    );
                  }}
                  style={{
                    border: "none",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
                    borderRadius: "30px",
                    height: "30px",
                    width: "50%",
                    color: "#fff",
                    fontSize: "15px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                    fontFamily: "Open Sans",
                    margin: "20px 0 0 0",
                    cursor: "pointer",
                    maxWidth: "300px",
                  }}
                >
                  Escoger otra
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(createAsesor());
                  }}
                  style={{
                    border: "none",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)",
                    borderRadius: "30px",
                    height: "30px",
                    width: "50%",
                    color: "#fff",
                    fontSize: "15px",
                    textTransform: "uppercase",
                    fontWeight: 500,
                    fontFamily: "Open Sans",
                    margin: "10px 0 0 0",
                    cursor: "pointer",
                    maxWidth: "300px",
                  }}
                >
                  Continuar
                </button>
              </div>
            ) : (
              <>
                {" "}
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: "0 0 10px 0",
                  }}
                  htmlFor="fileInput"
                >
                  <input
                    style={{ display: "none" }}
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={async (e) => {
                      let file = e.target.files ? e.target.files[0] : null;
                      let type = "";
                      let fileBase64 = "";
                      if (file) {
                        type = file.type;
                        let temp = await file.arrayBuffer();
                        fileBase64 = arrayBufferToBase64(temp);
                        // check if file is image
                        if (type.includes("image")) {
                          // check if file is png or jpg

                          // check if file is less than 5mb
                          if (file.size < 5000000) {
                            // set file name
                            dispatch(
                              changeCreateAsesorRequest({
                                ...asesorCreateRequest,
                                photoBase64: fileBase64,
                              })
                            );
                            setFileError("");
                          } else {
                            setFileError("El archivo no puede ser mayor a 5mb");
                          }
                        } else {
                          setFileError("El archivo debe ser una imagen");
                        }
                      }
                    }}
                  />
                  <Upload />
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "rgba(255,255,255,0.5)",
                      margin: "10px 0 0 0",
                    }}
                  >
                    ADJUNTAR IMAGEN
                  </div>
                  {fileError.length > 0 && (
                    <p
                      style={{
                        textAlign: "start",
                        fontSize: "10px",
                        fontWeight: 600,
                        //soft red color for error
                        color: "#FF5C5C",
                      }}
                    >
                      {fileError}
                    </p>
                  )}
                </label>
              </>
            )}
            <p
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontWeight: 400,
                color: "#fff",
                width: "60%",
                margin: "30px 0 70px 0",
              }}
            >
              Elige la mejor fotografía que tus clientes verán al consultar tu
              tarjeta de asesor
            </p>
            <p
              style={{
                width: "60%",
                textAlign: "start",
                fontSize: "10px",
                fontWeight: 600,
                color: "rgba(255,255,255,0.35)",
                margin: "20px 0",
              }}
            >
              Esta información se utiliza únicamente para validar tu identidad y
              es guardada de forma segura por anteia.
            </p>
          </>
        );
      } else if (page === 2) {
        return (
          <>
            <p
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontWeight: 800,
                color: "#fff",
                width: "60%",
                margin: "50px 0 10px 0",
              }}
            >
              Revisa tu correo electrónico
            </p>
            <p
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontWeight: 400,
                color: "#fff",
                width: "60%",
                margin: "0 0 20px 0",
              }}
            >
              Te hemos enviado un código QR como este
            </p>

            <QRCode />

            <p
              style={{
                textAlign: "start",
                fontSize: "20px",
                fontWeight: 400,
                color: "#fff",
                width: "60%",
                margin: "30px 0 30px 0",
              }}
            >
              A través de este código QR podrás ayudar a tus clientes a crear
              una vinculación
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 800,
                color: "#fff",
                width: "60%",
                margin: "0 0 30px 0",
              }}
            >
              Ya puedes cerrar esta ventana
            </p>
          </>
        );
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: page === 0 ? "start" : "center",
        alignItems: "center",
        width: "100vw",
        minHeight: "100vh",
        overflow: "auto",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(101.01% 98.98% at 100% 98.98%, #0081E3 0%, #002039 100%) ",
      }}
    >
      {getPageInfo()}
    </div>
  );
};
