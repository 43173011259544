import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useQuery from "../../utils/UseQuery";
import DummiesApi from "../../api/dummies_api";
import more from "./../../assets/more.png";

import "./ins_new.scss";
import {
  TextFieldStyleDummies,
  TextFieldStyleDummies2,
} from "../../utils/TextFieldStyle/TextFieldStyle";
import { ButtonIns, ButtonStyle } from "../../utils/Buttons/Buttons";
import logoIns from "../../assets/logoins.png";
import { infoMessage, successMessage } from "../../api/errorHandler";

function InsFormPayCallback(props) {
  let history = useHistory();
  var [bgColor, setBgColor] = useState(["#008946", "#006b39"]);
  const api = new DummiesApi();
  const query = useQuery();
  const filledFormId = query.get("filledFormId");

  const [value, setValue] = useState("");

  async function nextScreen() {
    history.push("/insFormEnd?filledFormId=" + filledFormId);
  }

  return (
    <div
      style={{
        background: `linear-gradient(to top, ${bgColor[0]}, ${bgColor[1]})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {/* Main Col */}
      <div className="col" style={{ marginLeft: "10vw", marginRight: "10vw" }}>
        {/* Aviso */}
        <h1
          style={{ marginTop: "40vh", marginBottom: "10vh" }}
          className="txt-welcome"
        >
          PAGO EXITOSO
        </h1>
      </div>
      {/* Button Next */}
      <div className="row btn-more">
        <div className="col">
          <ButtonStyle
            text="Continuar"
            onClick={() => nextScreen()}
            width="177px"
            height="5vh"
          />
        </div>
      </div>
    </div>
  );
}

export default InsFormPayCallback;
