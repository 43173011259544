import { useEffect, useState } from "react";
import useQuery from "../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import { ButtonStyle } from "../utils/Buttons/Buttons";
import { infoMessage } from "../api/errorHandler";
import { ConstructionOutlined } from "@mui/icons-material";
import { TextFieldStyleDummies } from "../utils/TextFieldStyle/TextFieldStyle";
import { showMessageCallback, getColorsByExecFlow } from "./hyperFlowApi";
import { useHistory } from "react-router-dom";
import "./showMessage.scss";

const HyperFlowShowMessage = () => {
  let history = useHistory();
  //query params
  const query = useQuery();
  const execFlowId = query.get("execFlowId") ?? "error";
  const code = query.get("code") ?? "error";
  const messageB64 = query.get("mb64") ?? "error";
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [darkMode, setDarkMode] = useState(true);
  const [showNextButton, setShowNextButton] = useState(true);

  //get colors
  async function getColors() {
    let resp = await getColorsByExecFlow(execFlowId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setDarkMode(resp.darkMode);
  }

  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  function b64_to_utf8(str: string): string {
    console.log(window.atob(str));
    return decodeURIComponent(window.atob(str));
  }

  async function getCurrentMessageF() {
    setLoading(true);
    try {
      let msg = b64DecodeUnicode(messageB64);
      await showMessageCallback(code);
      await delay(2000);
      if (msg.startsWith("NO_NEXT_BUTTON")) {
        setShowNextButton(false);
        msg = msg.replace("NO_NEXT_BUTTON", "");
      }
      setMessage(msg);
    } catch (e: any) {
      infoMessage(
        "Ocurrió un error, por favor intenta de nuevo en 30 segundos"
      );
      console.log(e);
    }

    setLoading(false);
  }

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function next() {
    try {
      history.push("/hyperFlow/continueFlow?code=" + code);
    } catch (e: any) {
      console.log(e);
    }
  }

  useEffect(() => {
    getColors();
    getCurrentMessageF();
  }, []);

  const customStyle = {
    "--fontColorPrimary": darkMode ? "#ffffff" : "#000000",
  } as React.CSSProperties;

  //Render
  return (
    <div className="hfMaster" style={customStyle}>
      <div
        style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            overflow: "auto",
          }}
        >
          {loading && (
            <h1
              className="txt-label-title-hf"
              style={{
                fontSize: "",
                marginBottom: "50px",
                position: "absolute",
                top: "50%",
                transform: "translate(-50%, -50%)",
                left: "50%",
              }}
            >
              Espera un momento
            </h1>
          )}

          {!loading && (
            <div className="show-message">
              <h1
                className="txt-label2"
                style={{
                  fontSize: "",
                  // marginTop: "10vh",
                  // marginBottom: "50px",
                  // marginLeft: "40px",
                  // marginRight: "40px",
                }}
              >
                {message}
              </h1>

              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "10px",
                }}
              ></div>
              {showNextButton && (<ButtonStyle
                onClick={() => {
                  next();
                }}
                text="Continuar"
                width="177px"
                height="5vh"
              />)}

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HyperFlowShowMessage;
